<template>
  <CustomerFeedbackList />
</template>

<script>
// @ is an alias to /src
import CustomerFeedbackList from "@/components/CustomerFeedbackList.vue";

export default {
  name: "CustomerFeedbackLists",
  components: {
    CustomerFeedbackList,
  },
};
</script>
