<template>
  <div class="content-wrapper" style="background: rgb(237, 240, 242)">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-5">
          <h1>Dashboard</h1>
        </div>
        <div class="col-xs-7 mandatoryDiv">
          <span class="letter-right">
            Last Refresh Time: {{ refresh_datetime }}</span
          >
          <a class="btn btn-sm btn-add" @click="refreshDashboard"
            ><i class="fa fa-refresh"></i> Refresh</a
          >
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-green"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Subscribed Users</span>

                  <span class="info-box-number" id="totalConnectedUsers">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ totSubscribedPin }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-warning"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Free Active Users </span>

                  <span class="info-box-number" id="totalUserOvpUdp">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{ totalFreeUsers }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-aqua"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Expire Users </span>

                  <span class="info-box-number" id="totalUserOvpUdp">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{
                      totalExpireUsers
                    }}</span>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-12">
              <div class="info-box">
                <span class="info-box-icon bg-aqua"
                  ><i class="ion ion-ios-people-outline"></i
                ></span>
                <div class="info-box-content">
                  <span class=""> Total Inactive Users </span>

                  <span class="info-box-number" id="totalUserOvpUdp">
                    <img
                      v-if="loading_data"
                      src="/assets/img/loading_h.gif"
                      alt="Loading"
                    />
                    <span v-else>{{
                      totalInactiveUsers
                    }}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Subscription Graph Start-->
      <SubscribePinsGraph v-if="renderComponent" />
      <!--Subscription Graph End-->

    </section>
  </div>
</template>
<script>
import axios from "axios";
import SubscribePinsGraph from "@/components/dashboardComponents/SubscribePinsGraph.vue";

export default {
  name: "Dashboard",
  components: {
    SubscribePinsGraph,
  },
  data() {
    return {
      renderComponent: true,
      refresh_datetime: "",
      loading_data: true,
      totSubscribedPin: "",
      totalInactiveUsers: "",
      reseller_list: {},
      paymentArr: [],
      rechargeArr: [],
      topResellerArr: [],
      totalFreeUsers: 0,
      totalExpireUsers:0,
      totalConnectedUsers: [],
      totalUsers: 0
    };
  },
  props: {
    msg: String,
  },
  created() {
    var rootObject = this;
    rootObject.loadDashboardData();
  },
  methods: {
    loadDashboardData() {
      var rootObj = this;
      loader.show();
      this.refresh_datetime = this.com_currentDateTime("dmy");

      const form = new FormData();
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("dashboard/load-dashboard-pin-count-data", form, config)
        .then(function (response) {
          var dashboardData = response.data.data;
          //console.log('data',dashboardData);

          rootObj.loading_data = false;
          rootObj.totSubscribedPin = dashboardData.totalSubscribedPin;
          rootObj.totalFreeUsers = parseInt(dashboardData.totalFreeUsers);
          rootObj.totalExpireUsers = parseInt(dashboardData.totalExpireUsers);
          rootObj.totalInactiveUsers = parseInt(
            dashboardData.totalInactiveUsers
          );
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObj.loading_data = false;
          loader.hide();
          rootObj.com_errorHandel(error);
        });

      setTimeout(function () {
        loader.hide();
      }, 1000);
    },

    populatePaymentArrData(response) {
      this.paymentArr = [];
      for (var prop in response.data.data) {
        response.data.data[prop]["amount"] = parseFloat(
          response.data.data[prop]["amount"]
        ).toFixed(2);
        var creationdate = this.com_displayDateFormat(
          response.data.data[prop]["creationdate"]
        );
        response.data.data[prop]["creationdate"] = creationdate.date;
        response.data.data[prop]["description"] = response.data.data[prop][
          "description"
        ].substr(0, 10);
        this.paymentArr.push(response.data.data[prop]);
      }
    },

    populateRechargeArrData(response) {
      //console.log(response);
      this.rechargeArr = [];
      for (var prop in response.data.data) {
        response.data.data[prop]["amount"] = parseFloat(
          response.data.data[prop]["amount"]
        ).toFixed(2);
        var creationdate = this.com_displayDateFormat(
          response.data.data[prop]["creationdate"]
        );
        response.data.data[prop]["creationdate"] = creationdate.date;
        this.rechargeArr.push(response.data.data[prop]);
      }
    },

    populateTopResellerArrData(response) {
      //console.log(response);
      this.topResellerArr = response.data.data;
    },

    refreshDashboard() {
      this.loadDashboardData();
      this.forceRerender();
    },

    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
  },
  mounted: function () {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
