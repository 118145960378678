<template>
  <AllResellerList />
</template>

<script>
// @ is an alias to /src
import AllResellerList from "@/components/AllResellerList.vue";

export default {
  name: "AllResellerLists",
  components: {
    AllResellerList,
  },
};
</script>
