<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>{{ LabelFormName }} IP Bundle</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">IP Bundle Information</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Bundle Name
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="name"
                      id="name"
                      class="form-control input-sm"
                      v-model="name"
                      value=""
                      placeholder="name"
                      type="text"
                    />
                    <label v-if="error_name" class="error" for="name">{{
                      error_name
                    }}</label>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-md-4 control-label">Descriptions</label>
                  <div class="col-md-8">
                    <textarea
                      name="description"
                      v-model="description"
                      id="description"
                      class="form-control input-sm"
                      value=""
                      placeholder="Note"
                      type="text"
                      rows="4"
                      cols="2"
                    >
                    </textarea>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Active</label>
                  <div class="col-md-8">
                    <input
                      name="status"
                      type="checkbox"
                      v-model="status"
                      id="status"
                      checked="checked"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddServers",
  data() {
    return {
      id: "",
      name: "",
      description: "",
      status: true,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Add New",

      error_name: false,
    };
  },
  created() {
    var rootObject = this;
    if (this.$route.params.id) {
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    getEditData() {
      loader.show();
      let rootObject = this;
      rootObject.LabelFormName = "Edit";
      axios
        .get("ip-bundles/" + rootObject.id)
        .then(function (response) {
          rootObject.name = response.data.name;
          rootObject.description = response.data.description;
          if (response.data.status != 1) {
            rootObject.status = false;
          }
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_name = false;
        }, 5000);
        return 0;
      }
      if (this.status === true) {
        var status = 1;
      } else {
        var status = 2;
      }
      loader.show();
      let querystr = "";
      const form = new FormData();
      form.append("name", this.name);
      form.append("description", this.description);
      form.append("status", status);
      if (rootObject.isEditing) {
        form.append("_method", "put");
        querystr = "/" + rootObject.id;
      }
      console.log(querystr);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("ip-bundles" + querystr, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          if (!rootObject.isEditing) {
            rootObject.name = "";
            rootObject.description = "";
            rootObject.status = true;
          }
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.name) {
        this.error_name = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
