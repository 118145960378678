<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Application Configurations</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-sm-7">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Server Configuration</h3>
            </div>
            <div class="box-body form-box-body form-horizontal">
              <div class="form-group">
                <label class="col-md-4 control-label">
                  Scratchcard User
                  <span class="mandatory_horizontal">*</span>
                </label>
                <div class="col-md-8">
                  <select
                    title
                    name="general_ipbundle_id"
                    v-model="general_ipbundle_id"
                    v-select="general_ipbundle_id"
                    id="general_ipbundle_id"
                    class="form-control input-sm select2"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                  >
                    <option value>Select VPN Server Bundle</option>
                    <option
                      v-for="data in bundles"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                  <label
                    v-if="error_general_ipbundle_id"
                    class="error"
                    for="general_ipbundle_id"
                    >{{ error_general_ipbundle_id }}</label
                  >
                </div>
              </div>

              <div class="form-group">
                <label class="col-md-4 control-label">
                  Free User
                  <span class="mandatory_horizontal">*</span>
                </label>
                <div class="col-md-8">
                  <select
                    title
                    name="general_ipbundle_id"
                    v-model="free_ipbundle_id"
                    v-select="free_ipbundle_id"
                    id="free_ipbundle_id"
                    class="form-control input-sm select2"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                  >
                    <option value>Select VPN Server Bundle</option>
                    <option
                      v-for="data in bundles"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                  <label
                    v-if="error_free_ipbundle_id"
                    class="error"
                    for="free_ipbundle_id"
                    >{{ error_free_ipbundle_id }}</label
                  >
                </div>
              </div>

              <div class="form-group">
                <label class="col-md-4 control-label">
                  Premium User
                  <span class="mandatory_horizontal">*</span>
                </label>
                <div class="col-md-8">
                  <select
                    title
                    name="general_ipbundle_id"
                    v-model="paid_ipbundle_id"
                    v-select="paid_ipbundle_id"
                    id="paid_ipbundle_id"
                    class="form-control input-sm select2"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                  >
                    <option value>Select VPN Server Bundle</option>
                    <option
                      v-for="data in bundles"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>

                  <label
                    v-if="error_paid_ipbundle_id"
                    class="error"
                    for="paid_ipbundle_id"
                    >{{ error_paid_ipbundle_id }}</label
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label">Number Of Devices</label>
                <div class="col-md-8">
                  <input
                    name="number_of_device_allowed"
                    id="number_of_device_allowed"
                    class="form-control input-sm stop-specialchar"
                    v-model="number_of_device_allowed"
                    value
                    placeholder="Number Of Devices"
                    type="text"
                    onfocus="this.removeAttribute('readonly');"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 btnCenter">
          <input
            type="submit"
            name="submit"
            value="Save"
            class="btn btn-sm btn-submit btn-submit-big"
            @click="formSubmit"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddConfigSetting",
  data() {
    return {
      userData: {},
      bundles: {},
      general_ipbundle_id: "",
      anti_tracker: "",
      number_of_device_allowed: "",
      adult_content_blocker: "",
      free_ipbundle_id: "",
      paid_ipbundle_id: "",
      alert_message: "",
      error_general_ipbundle_id: false,
      error_free_ipbundle_id: false,
      error_paid_ipbundle_id: false,
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);
    var rootObject = this;
    rootObject.getEditData();
    loader.show();
    axios
      .get("get-ip-bundle-dropdown")
      .then(function (response) {
        rootObject.bundles = response.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },
  methods: {
    getEditData() {
      loader.show();
      let rootObject = this;
      axios
        .get("settings")
        .then(function (response) {
          //console.log(response.data)
          rootObject.general_ipbundle_id = response.data.general_user_ip_bundle;
          rootObject.free_ipbundle_id = response.data.free_user_ip_bundle;
          rootObject.paid_ipbundle_id = response.data.paid_user_ip_bundle;
          rootObject.anti_tracker = response.data.anti_tracker;
          rootObject.number_of_device_allowed =
            response.data.number_of_device_allowed;
          rootObject.adult_content_blocker =
            response.data.adult_content_blocker;

          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_general_ipbundle_id = false;
          rootObject.error_free_ipbundle_id = false;
          rootObject.error_paid_ipbundle_id = false;
        }, 5000);

        return 0;
      }

      loader.show();
      const form = new FormData();
      form.append("general_user_ip_bundle", this.general_ipbundle_id);
      form.append("free_user_ip_bundle", this.free_ipbundle_id);
      form.append("paid_user_ip_bundle", this.paid_ipbundle_id);
      form.append("anti_tracker", this.anti_tracker);
      form.append("number_of_device_allowed", this.number_of_device_allowed);
      form.append("adult_content_blocker", this.adult_content_blocker);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("app-config-save", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = "Successfully Saved";
          rootObject.username = "";
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });
      setTimeout(function () {
        rootObject.alert_message = "";
        rootObject.error_general_ipbundle_id = false;
        rootObject.error_free_ipbundle_id = false;
        rootObject.error_paid_ipbundle_id = false;
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;

      if (!this.general_ipbundle_id) {
        this.error_general_ipbundle_id = "This field is required";
        has_error = true;
      }

      if (!this.free_ipbundle_id) {
        this.error_free_ipbundle_id = "This field is required";
        has_error = true;
      }

      if (!this.paid_ipbundle_id) {
        this.error_paid_ipbundle_id = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
