<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Edit Reseller Client</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <form>
          <div class="col-sm-12">
            <div class="alert alert-info" v-if="error_message">
              {{ error_message }}
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Basic Configuration</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Reseller Name
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="full_name"
                      id="full_name"
                      class="form-control input-sm"
                      value=""
                      placeholder="Reseller Name"
                      type="text"
                      v-model="full_name"
                    />
                    <label
                      v-if="error_full_name"
                      class="error"
                      for="full_name"
                      >{{ error_full_name }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Login<span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="login"
                      id="login"
                      class="form-control input-sm"
                      value=""
                      placeholder="Login Username"
                      type="text"
                      v-model="login"
                      readonly=""
                      disabled=""
                    />
                    <label v-if="error_login" class="error" for="login">{{
                      error_login
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Password <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="password"
                      id="complex-password"
                      class="form-control input-sm"
                      maxlength="100"
                      value=""
                      placeholder="Login Password"
                      type="text"
                      v-model="password"
                    />
                    <label v-if="error_password" class="error" for="password">{{
                      error_password
                    }}</label>
                    <label class="field_suggestion">
                      1. At least 1 Character, 1 Number, 1 Special Character.<br />
                      2. No space is allowed for password <br />
                      3. Only allowed !@# <br />
                      4. Min Character is 6, Max Character is 17
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >VPN Current Balance</label
                  >
                  <div class="col-md-4">
                    <input
                      name="balance"
                      id="balance"
                      class="form-control input-sm valid stop-negative-value"
                      value=""
                      placeholder="Current Balance"
                      type="text"
                      readonly=""
                      aria-invalid="false"
                      v-model="balance"
                    />
                  </div>
                  <div class="col-md-4 btnRight" style="display: none">
                    <a
                      style="margin-top: 2px"
                      href="#"
                      title="Add Payment"
                      class="btn btn-sm btn-payment colorbox cboxElement"
                    >
                      <i class="ion ion-cash"></i>
                      <span>Add Payment</span>
                    </a>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >VPN Credit Limit
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="creditLimit"
                      id="creditLimit"
                      class="form-control input-sm stop-negative-value"
                      value=""
                      placeholder="Credit Limit"
                      type="text"
                      v-model="creditLimit"
                    />
                    <label
                      v-if="error_creditLimit"
                      class="error"
                      for="creditLimit"
                      >{{ error_creditLimit }}</label
                    >
                    <label class="field_suggestion"
                      >Postpaid balance [After Initial Balance This amount of
                      money is used to make a call(Reseller OWN call and PIN
                      call)].</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >VPN Rate Plan
                    <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <select
                      title=""
                      name="rate_plan_id"
                      id="rate_plan_id"
                      class="form-control input-sm select2"
                      v-model="rate_plan_id"
                      v-select="rate_plan_id"
                      disabled=""
                      readonly
                    >
                      <option value="">Select Plan</option>
                      <option v-for="plan in rate_plans" v-bind:value="plan.id">
                        {{ plan.name }}
                      </option>
                    </select>
                    <label
                      v-if="error_rate_plan_id"
                      class="error"
                      for="rate_plan_id"
                      >{{ error_rate_plan_id }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Active</label>
                  <div class="col-md-8">
                    <input
                      name="status"
                      type="checkbox"
                      id="status"
                      value=""
                      checked="checked"
                      v-model="status"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Contact Information</h3>
              </div>
              <div
                class="box-body form-box-body form-horizontal"
                id="secondShowHide"
              >
                <div class="form-group">
                  <label class="col-md-4 control-label"
                    >Email <span class="mandatory_horizontal">*</span></label
                  >
                  <div class="col-md-8">
                    <input
                      name="email"
                      id="email"
                      class="form-control input-sm"
                      value=""
                      placeholder="Email"
                      type="text"
                      v-model="email"
                    />
                    <label class="field_suggestion"
                      >Example: abcd@gmail.com</label
                    >
                    <label v-if="error_email" class="error" for="email">{{
                      error_email
                    }}</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Phone No</label>
                  <div class="col-md-8">
                    <input
                      name="phone"
                      id="phone"
                      class="form-control input-sm"
                      value=""
                      placeholder="Phone No"
                      type="text"
                      v-model="phone"
                    />
                    <label class="field_suggestion">Example: 971XXXXXXX</label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">City</label>
                  <div class="col-md-8">
                    <input
                      name="city"
                      id="city"
                      class="form-control input-sm"
                      value=""
                      placeholder="City"
                      type="text"
                      v-model="city"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Country</label>
                  <div class="col-md-8">
                    <select
                      title=""
                      name="country"
                      id="country"
                      class="form-control input-sm select2"
                      v-model="country"
                      v-select="country"
                    >
                      <option value="">Select Country</option>
                      <option
                        v-for="(country, index) in country_arr"
                        v-bind:value="index"
                      >
                        {{ country }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">State</label>
                  <div class="col-md-8">
                    <input
                      name="state"
                      id="state"
                      class="form-control input-sm"
                      value=""
                      placeholder="State"
                      type="text"
                      v-model="state"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Address</label>
                  <div class="col-md-8">
                    <textarea
                      name="address"
                      id="address"
                      class="form-control input-sm"
                      rows="3"
                      placeholder="Address"
                      v-model="address"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "EditReseller",
  data() {
    return {
      country_arr: {},
      rate_plans: {},
      id: 0,
      full_name: "",
      login: "",
      password: "",
      creditLimit: "",
      rate_plan_id: "",
      status: false,
      limit_user: 0,
      balance: "",
      limit_balance: "",
      email: "",
      phone: "",
      city: "",
      country: "",
      state: "",
      address: "",
      error_message: "",

      error_full_name: false,
      error_login: false,
      error_password: false,
      error_creditLimit: false,
      error_rate_plan_id: false,
      error_limit_user: false,
      error_limit_balance: false,
      error_email: false,
    };
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        return 0;
      }

      loader.show();

      let status = 1;
      if (!this.status) {
        status = 0;
      }

      const form = new FormData();
      form.append("full_name", this.full_name);
      form.append("login", this.login);
      form.append("password", this.password);
      form.append("creditLimit", this.creditLimit);
      form.append("status", status);
      form.append("limit_user", this.limit_user);
      form.append("limit_balance", this.limit_balance);
      form.append("balance", this.balance);
      form.append("email", this.email);
      form.append("phone", this.phone);
      form.append("city", this.city);
      form.append("country", this.country);
      form.append("state", this.state);
      form.append("address", this.address);
      form.append("_method", "put");

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("resellers/" + this.id, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.error_message = "Reseller Successfully Updated";
          loader.hide();
          setTimeout(function () {
            rootObject.error_message = "";
          }, 2000);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
          rootObject.error_message = alertmessage;
          setTimeout(function () {
            rootObject.error_message = "";
          }, 5200);
        });
    },

    fromValidation(reset = false) {
      var has_error = false;
      this.error_full_name = false;
      this.error_login = false;
      this.error_password = false;
      this.error_creditLimit = false;
      this.error_rate_plan_id = false;
      this.error_limit_user = false;
      this.error_limit_balance = false;
      this.error_email = false;

      if (!this.full_name) {
        this.error_full_name = "This field is required";
        has_error = true;
      }

      if (!this.login) {
        this.error_login = "This field is required";
        has_error = true;
      }

      if (!this.password) {
        this.error_password = "This field is required";
        has_error = true;
      }

      if (!this.creditLimit) {
        this.error_creditLimit = "This field is required";
        has_error = true;
      }
      if (!this.rate_plan_id) {
        this.error_rate_plan_id = "This field is required";
        has_error = true;
      }

      if (!this.email) {
        this.error_email = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    loader.show();
    var resellerId = this.$route.params.id;
    var rootObject = this;
    axios
      .get("get-countries")
      .then(function (response) {
        rootObject.country_arr = response.data;
      })
      .catch(function (error) {
        rootObject.com_errorHandel(error);
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });

    axios
      .get("get-rates-plan")
      .then(function (response) {
        rootObject.rate_plans = response.data;
      })
      .catch(function (error) {
        rootObject.com_errorHandel(error);
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
      });

    axios
      .get("resellers/" + resellerId)
      .then(function (response) {
        //console.log(response.data.result);
        rootObject.full_name = response.data.result.full_name;
        rootObject.login = response.data.result.login;
        rootObject.password = response.data.result.reseller_name;
        rootObject.creditLimit = response.data.result.creditLimit;
        rootObject.rate_plan_id = response.data.result.rate_plan_id;
        rootObject.status = false;
        if (response.data.result.status == 1) {
          rootObject.status = true;
        }
        rootObject.limit_user = parseInt(response.data.result.limit_user) * 1;
        rootObject.limit_balance = response.data.result.limit_balance;
        rootObject.balance = response.data.result.balance;
        rootObject.email = response.data.result.email;
        rootObject.phone = response.data.result.phone;
        rootObject.city = response.data.result.city;
        rootObject.country = response.data.result.country;
        rootObject.state = response.data.result.state;
        rootObject.address = response.data.result.address;
        rootObject.id = response.data.result.id;
        //console.log(rootObject.limit_user);
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },

  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
