<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>{{ LabelFormName }} Banner</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-sm-12">
                <div class="box">
                  <div class="box-header">
                    <h3 class="box-title">Banner Information Form</h3>
                  </div>
                  <div class="box-body form-box-body form-horizontal">
                    <div class="form-group">
                      <label class="col-md-2 control-label">
                        Banner Name
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-10">
                        <input
                          name="banner_name"
                          id="banner_name"
                          class="form-control input-sm"
                          v-model="banner_name"
                          value
                          placeholder="Banner Name"
                          type="text"
                          readonly
                          onfocus="this.removeAttribute('readonly');"
                        />
                        <label
                          v-if="error_banner_name"
                          class="error"
                          for="banner_name"
                          >{{ error_banner_name }}</label
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label
                        class="field_suggestion"
                        style="text-align: center"
                      >
                        <i>* In app ad banner size will be 320x50 *</i>
                      </label>
                      <label class="col-md-2 control-label"
                        >Banner For Android</label
                      >
                      <div class="col-md-4">
                        <input
                          name="android_banner_img"
                          v-model="android_banner_img"
                          id="android_banner_img"
                          class="form-control input-sm"
                          value
                          placeholder="Enter Src URL For App Banner Android"
                          type="text"
                          readonly
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                      <label class="col-md-2 control-label"
                        >OnClick URL Android</label
                      >
                      <div class="col-md-4">
                        <input
                          name="android_banner_onclick_link"
                          v-model="android_banner_onclick_link"
                          id="android_banner_onclick_link"
                          class="form-control input-sm"
                          value
                          placeholder="Enter Android Click Action"
                          type="text"
                          readonly
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-md-2 control-label"
                        >Banner For IOS</label
                      >
                      <div class="col-md-4">
                        <input
                          name="ios_banner_img"
                          v-model="ios_banner_img"
                          id="ios_banner_img"
                          class="form-control input-sm"
                          value
                          placeholder="Enter Src URL For App Banner IOS"
                          type="text"
                          readonly
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                      <label class="col-md-2 control-label"
                        >OnClick URL IOS</label
                      >
                      <div class="col-md-4">
                        <input
                          name="ios_banner_onclick_link"
                          v-model="ios_banner_onclick_link"
                          id="ios_banner_onclick_link"
                          class="form-control input-sm"
                          value
                          placeholder="Enter IOS Click Action"
                          type="text"
                          readonly
                          onfocus="this.removeAttribute('readonly');"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-2 control-label">
                        User Type
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-2">
                        <label class="col-md-8 control-label">All</label>
                        <div class="col-md-4">
                          <input
                            name="is_all"
                            type="checkbox"
                            v-model="is_all"
                            id="is_all"
                          />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <label class="col-md-8 control-label">Free User</label>
                        <div class="col-md-4">
                          <input
                            name="is_free"
                            type="checkbox"
                            v-model="is_free"
                            id="is_free"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="col-md-8 control-label"
                          >Premium User</label
                        >
                        <div class="col-md-4">
                          <input
                            name="is_premium"
                            type="checkbox"
                            v-model="is_premium"
                            id="is_premium"
                          />
                        </div>
                      </div>
                      <div class="col-md-3">
                        <label class="col-md-8 control-label"
                          >Reseller User</label
                        >
                        <div class="col-md-4">
                          <input
                            name="is_reseller"
                            type="checkbox"
                            v-model="is_reseller"
                            id="is_reseller"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-2 control-label">Status</label>
                      <div class="col-md-6">
                        <input
                          name="status"
                          type="checkbox"
                          v-model="status"
                          id="status"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-8 btnRight">
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  class="btn btn-sm btn-submit btn-submit-big"
                  @click="formSubmit"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "BannerApps",
  data() {
    return {
      id: "",
      banner_name: "",
      android_banner_img: "",
      android_banner_onclick_link: "",
      ios_banner_img: "",
      ios_banner_onclick_link: "",
      is_all: false,
      is_free: false,
      is_premium: false,
      is_reseller: false,
      status: true,

      error_banner_name: false,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Create New",
    };
  },
  created() {
    var rootObject = this;
    if (this.$route.params.id) {
      rootObject.LabelFormName = "Edit";
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    getEditData() {
      let rootObject = this;
      axios
        .get("ads-banner/" + rootObject.id)
        .then(function (response) {
          //console.log(response.data);
          rootObject.banner_name = response.data.banner_name;
          rootObject.android_banner_img = response.data.android_banner_img;
          rootObject.android_banner_onclick_link =
            response.data.android_banner_onclick_link;
          rootObject.ios_banner_img = response.data.ios_banner_img;
          rootObject.ios_banner_onclick_link =
            response.data.ios_banner_onclick_link;

          if (response.data.status == 1) {
            rootObject.status = true;
          } else {
            rootObject.status = false;
          }

          rootObject.is_all =
            rootObject.com_CheckUserType(response.data.user_type, 0) ?? true;
          rootObject.is_free =
            rootObject.com_CheckUserType(response.data.user_type, 3) ?? true;
          rootObject.is_premium =
            rootObject.com_CheckUserType(response.data.user_type, 2) ?? true;
          rootObject.is_reseller =
            rootObject.com_CheckUserType(response.data.user_type, 1) ?? true;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_banner_name = false;
        }, 5000);
        return 0;
      }

      loader.show();
      let querystr = "";
      const form = new FormData();
      form.append("banner_name", this.banner_name);
      form.append("android_banner_img", this.android_banner_img);
      form.append(
        "android_banner_onclick_link",
        this.android_banner_onclick_link
      );
      form.append("ios_banner_img", this.ios_banner_img);
      form.append("ios_banner_onclick_link", this.ios_banner_onclick_link);

      var user_type = [];
      if (this.is_all === true) {
        user_type.push(0);
      }
      if (this.is_reseller === true) {
        user_type.push(1);
      }
      if (this.is_premium === true) {
        user_type.push(2);
      }
      if (this.is_free === true) {
        user_type.push(3);
      }

      var status = this.status === true ? 1 : 0;
      form.append("user_type", user_type.length > 0 ? user_type : "0");
      form.append("status", status);

      if (rootObject.isEditing) {
        form.append("_method", "put");
        querystr = "/" + rootObject.id;
      }
      //console.log(querystr)

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("ads-banner" + querystr, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          if (!rootObject.isEditing) {
            rootObject.banner_name = "";
          }
          rootObject.loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.banner_name) {
        this.error_banner_name = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
