<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Send Push to Users</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <div class="box">
                  <div class="box-header">
                    <h3 class="box-title">
                      Fill up the Subscription Information
                    </h3>
                  </div>
                  <div class="box-body form-box-body form-horizontal">
                    <div class="form-group">
                      <label class="col-md-4 control-label">
                        App Name
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-8">
                        <select
                          title
                          name="app_id"
                          id="app_id"
                          v-model="app_id"
                          v-select="app_id"
                          class="form-control input-sm select2"
                        >
                          <option value>-- Select --</option>
                          <option
                            v-for="(data, index) in appArr"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.app_name }}
                          </option>
                        </select>
                        <label v-if="error_app_id" class="error" for="app_id">
                          {{ error_app_id }}
                        </label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Send To</label>
                      <div class="col-md-5">
                        <select
                          name="pin_type"
                          id="pin_type"
                          v-model="pin_type"
                          class="form-control input-sm"
                          @change="showPinTypeDiv"
                        >
                          <option value="0">Single Subscriptions</option>
                          <option value="4">
                            Reseller4 Active Subscriptions
                          </option>
                          <option value="3">
                            Reseller3 Active Subscriptions
                          </option>
                          <option value="2">
                            Reseller2 Active Subscriptions
                          </option>
                          <option value="1">
                            Reseller1 Active Subscriptions
                          </option>
                        </select>
                      </div>

                      <div class="col-md-3">
                        <input
                          name="with_child"
                          type="checkbox"
                          v-model="with_child"
                          id="with_child"
                          checked="checked"
                        />
                        With Child
                      </div>
                    </div>

                    <div class="form-group reseller-div">
                      <label class="col-md-4 control-label"
                        >Select Reseller</label
                      >
                      <div class="col-md-5">
                        <select
                          name="id_reseller"
                          id="id_reseller"
                          v-model="id_reseller"
                          class="form-control input-sm"
                        >
                          <option value>Select Reseller</option>
                          <option
                            v-for="(data, index) in resellerArr"
                            :key="index"
                            :value="index"
                          >
                            {{ data }}
                          </option>
                        </select>
                      </div>
                      <div class="col-md-2 is_self_class">
                        <input
                          name="is_self"
                          type="checkbox"
                          v-model="is_self"
                          id="is_self"
                        />
                        Self
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">
                        Subscription with comma separated
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-8">
                        <textarea
                          name="username"
                          v-model="username"
                          id="username"
                          class="form-control input-sm"
                          cols="30"
                          rows="5"
                          placeholder="Enter Subscription e.g. 12345,65412,1233,12345,65412,1233"
                        ></textarea>
                        <label
                          v-if="error_username"
                          class="error"
                          for="heading"
                          >{{ error_username }}</label
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Pin Status</label>
                      <div class="col-md-8">
                        <div class="form-group">
                          <div class="col-md-4">
                            <input
                              name="active_status"
                              type="checkbox"
                              v-model="active_status"
                              id="active_status"
                              checked="checked"
                            />
                            Active
                          </div>
                          <div class="col-md-4">
                            <input
                              name="inactive_status"
                              type="checkbox"
                              v-model="inactive_status"
                              id="inactive_status"
                              checked="checked"
                            />
                            Inactive
                          </div>
                          <div class="col-md-4">
                            <input
                              name="expired_status"
                              type="checkbox"
                              v-model="expired_status"
                              id="expired_status"
                              checked="checked"
                            />
                            Expired
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">
                        Target Device
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-8">
                        <select
                          name="device_type"
                          id="device_type"
                          v-model="device_type"
                          class="form-control input-sm"
                        >
                          <option value>All Devices</option>
                          <option value="1">Android Only</option>
                          <option value="2">iOS Only</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Date Filter Column</label
                      >
                      <div class="col-md-4">
                        <select
                          name="date_column"
                          id="date_column"
                          class="form-control input-sm"
                          v-model="date_column"
                        >
                          <option value="after_activate_first_login_at">
                            First login at
                          </option>
                          <option value="activate_at">Activate at</option>
                          <option value="validity_date">Expired at</option>
                        </select>
                      </div>
                      <div class="col-md-4">
                        <select
                          name="date_condition"
                          id="date_condition"
                          v-model="date_condition"
                          @change="checkDateCond"
                          class="form-control input-sm"
                        >
                          <option value>Filter Condition</option>
                          <option value="between">Between</option>
                          <option value="not between">Not Between</option>
                          <option value="=">Is Equal To</option>
                          <option value=">">&gt;</option>
                          <option value=">=">&gt;=</option>
                          <option value="<">&lt;</option>
                          <option value="<=">&lt;=</option>
                          <option value="!=">!=</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Date</label>
                      <div class="col-md-4">
                        <input
                          placeholder="Start"
                          type="text"
                          value
                          name="starttime"
                          id="starttime"
                          v-model="starttime"
                          class="form-control input-sm datePicker"
                          required
                        />
                      </div>
                      <div class="col-md-4" id="end-date-div">
                        <input
                          placeholder="End"
                          type="text"
                          value
                          name="stoptime"
                          id="stoptime"
                          v-model="stoptime"
                          class="form-control input-sm datePicker"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-12">
                <div class="box">
                  <div class="box-header">
                    <h3 class="box-title">Fill up Push Template Information</h3>
                  </div>
                  <div class="box-body form-box-body form-horizontal">
                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Copy Template</label
                      >
                      <div class="col-md-8">
                        <select
                          title
                          name="template_id"
                          id="template_id"
                          v-model="template_id"
                          v-select="template_id"
                          class="form-control input-sm select2"
                          @change="changeTemplateData"
                        >
                          <option value>-- Select --</option>
                          <option
                            v-for="(data, index) in templateArr"
                            :key="index"
                            :value="data.id"
                          >
                            {{ data.heading }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">
                        Title
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-8">
                        <input
                          name="heading"
                          v-model="heading"
                          id="heading"
                          class="form-control input-sm"
                          value
                          placeholder="Enter Push Title"
                          type="text"
                        />
                        <label
                          v-if="error_heading"
                          class="error"
                          for="heading"
                          >{{ error_heading }}</label
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">
                        Message
                        <span class="mandatory_horizontal">*</span>
                      </label>
                      <div class="col-md-8">
                        <textarea
                          name="push_message"
                          v-model="push_message"
                          id="push_message"
                          class="form-control input-sm"
                          cols="30"
                          rows="5"
                          placeholder="Enter Push Message"
                        ></textarea>
                        <label
                          v-if="error_push_message"
                          class="error"
                          for="heading"
                          >{{ error_push_message }}</label
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Image URL</label>
                      <div class="col-md-8">
                        <div>
                          <input
                            name="big_picture"
                            v-model="big_picture"
                            id="big_picture"
                            class="form-control input-sm"
                            value
                            placeholder="Image URL"
                            type="text"
                          />
                        </div>
                        <label class="field_suggestion">
                          <i>Image size will be 1010x450</i>
                        </label>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >URL for Android</label
                      >
                      <div class="col-md-8">
                        <input
                          name="launch_url_android"
                          v-model="launch_url_android"
                          id="launch_url_android"
                          class="form-control input-sm"
                          value
                          placeholder="URL for Android"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">URL for iOS</label>
                      <div class="col-md-8">
                        <input
                          name="launch_url_ios"
                          v-model="launch_url_ios"
                          id="launch_url_ios"
                          class="form-control input-sm"
                          value
                          placeholder="URL for iOS"
                          type="text"
                        />
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label">Page Control</label>
                      <div class="col-md-8">
                        <select
                          name="page_control"
                          id="page_control"
                          v-model="page_control"
                          class="form-control input-sm"
                        >
                          <option value>-- Select --</option>
                          <option value="Refresh">Refresh</option>
                          <option value="Price">Price</option>
                        </select>
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="col-md-4 control-label"
                        >Save as Template</label
                      >
                      <div class="col-md-3">
                        <input
                          name="save_as_template"
                          type="checkbox"
                          v-model="save_as_template"
                          id="save_as_template"
                          checked="checked"
                        />
                        Yes
                      </div>
                      <div class="col-md-2 pull-right">
                        <a
                          href="#"
                          @click="clearTemplateFormVal"
                          class="btn bg-red-gradient btn-sm btn-warning btn-payment templateform"
                          >Clear</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddPushs",
  data() {
    return {
      app_id: "",
      pin_type: 0,
      with_child: false,
      is_self: false,
      is_self_reseller_value: "1:4",
      id_reseller: "",
      username: "",
      active_status: true,
      inactive_status: false,
      expired_status: false,
      country_filter_type: "",
      country: [],
      device_type: "",
      date_column: "after_activate_first_login_at",
      date_condition: "=",
      starttime: "",
      stoptime: "",

      template_id: "",
      heading: "",
      push_message: "",
      big_picture: "",
      launch_url_android: "",
      launch_url_ios: "",
      page_control: "",
      save_as_template: false,
      appArr: {},
      resellerArr: {},
      templateArr: {},

      error_heading: false,
      error_push_message: false,

      error_app_id: false,
      error_username: false,

      alert_message: "",
    };
  },
  created() {
    this.loadAppDropdown();
    this.loadResellerDropdown();
    this.loadTemplateDropdown();
  },
  methods: {
    loadAppDropdown() {
      loader.show();
      let rootObject = this;
      axios
        .get("get-app-dropdown-data")
        .then(function (response) {
          //console.log(response.data);
          rootObject.appArr = response.data;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    loadResellerDropdown() {
      loader.show();
      let rootObject = this;
      axios
        .get("get-reseller-dropdown-data")
        .then(function (response) {
          rootObject.resellerArr = response.data.data;
          //console.log(rootObject.resellerArr);
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    loadTemplateDropdown() {
      loader.show();
      let rootObject = this;
      axios
        .get("get-template-dropdown-data")
        .then(function (response) {
          rootObject.templateArr = response.data;
          //console.log(rootObject.templateArr);
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    checkDateCond() {
      if (
        this.date_condition === "not between" ||
        this.date_condition === "between"
      ) {
        $("#end-date-div").show();
      } else {
        $("#end-date-div").hide();
        this.stoptime = "";
      }
      //console.log(this.stoptime);
    },

    showPinTypeDiv() {
      $(".reseller-div").hide();
      $(".is_self_class").hide();
      this.id_reseller = "";
      if (this.pin_type > 0) {
        $(".reseller-div").show();
      }

      if (this.pin_type == 4) {
        $(".is_self_class").show();
      } else {
        $(".is_self_class").hide();
      }
    },

    clearTemplateFormVal() {
      this.heading = "";
      this.push_message = "";
      this.big_picture = "";
      this.launch_url_android = "";
      this.launch_url_ios = "";
      this.page_control = "";
      this.save_as_template = "";
    },

    changeTemplateData() {
      var templateData = {};
      var template_id = this.template_id;
      var templateArr = this.templateArr;
      if (template_id > 0) {
        templateData = templateArr[template_id];
        this.heading = templateData.heading;
        this.push_message = templateData.push_message;
        this.big_picture = templateData.big_picture;
        this.launch_url_android = templateData.launch_url_android;
        this.launch_url_ios = templateData.launch_url_ios;
        this.page_control = templateData.page_control
          ? templateData.page_control
          : "";
        this.save_as_template = templateData.save_as_template;
      } else {
        this.clearTemplateFormVal();
      }
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_push_message = false;
          rootObject.error_heading = false;
          rootObject.error_app_id = false;
        }, 5000);
        return 0;
      }

      //loader.show();
      let save_as_template = this.save_as_template === true ? 1 : 0;
      let with_child = this.with_child === true ? 1 : 0;
      let is_self = this.is_self === true ? 1 : 0;
      let active_status = this.active_status === true ? 1 : 0;
      let inactive_status = this.inactive_status === true ? 1 : 0;
      let expired_status = this.expired_status === true ? 1 : 0;
      const form = new FormData();
      form.append("heading", this.heading);
      form.append("push_message", this.push_message);
      form.append("big_picture", this.big_picture);
      form.append("launch_url_android", this.launch_url_android);
      form.append("launch_url_ios", this.launch_url_ios);
      form.append("page_control", this.page_control);
      form.append("save_as_template", save_as_template);

      form.append("app_id", this.app_id);
      form.append("pin_type", this.pin_type);
      if (is_self === 1) {
        form.append("id_reseller", this.is_self_reseller_value);
      } else {
        form.append("id_reseller", this.id_reseller);
      }
      form.append("username", this.username);
      form.append("country_filter_type", this.country_filter_type);
      form.append("device_type", this.device_type);
      form.append("date_column", this.date_column);
      form.append("date_condition", this.date_condition);

      form.append("starttime", this.starttime);
      form.append("stoptime", this.stoptime);
      form.append("with_child", with_child);
      form.append("active_status", active_status);
      form.append("expired_status", expired_status);
      form.append("inactive_status", inactive_status);
      form.append("template_id", this.template_id);
      form.append("country", this.country);

      //console.log(this.country);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("push", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          rootObject.loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation() {
      var has_error = false;

      if (!this.heading) {
        this.error_heading = "This field is required";
        has_error = true;
      }

      if (!this.push_message) {
        this.error_push_message = "This field is required";
        has_error = true;
      }

      if (!this.app_id) {
        this.error_app_id = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },

    handleChange() {
      this.country = $("#country").val();
    },
  },
  mounted: function () {
    var rootObject = this;
    $(".select2").select2();
    $("#starttime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.starttime = $(this).val();
      });

    $("#stoptime")
      .datetimepicker({
        timepicker: false,
        format: "Y-m-d",
      })
      .on("change", function (e) {
        rootObject.stoptime = $(this).val();
      });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.templateform {
  float: right;
}
#end-date-div,
.reseller-div,
.is_self_class {
  display: none;
}
</style>
