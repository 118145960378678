<template>
  <AddBanner />
</template>

<script>
// @ is an alias to /src
import AddBanner from "@/components/BannerLists/BannerForm.vue";

export default {
  name: "AddBanners",
  components: {
    AddBanner,
  },
};
</script>
