<template>
  <BannerList />
</template>

<script>
// @ is an alias to /src
import BannerList from "@/components/BannerLists/BannerLists.vue";

export default {
  name: "BannerLists",
  components: {
    BannerList,
  },
};
</script>
