<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-10">
          <h1>Banner List</h1>
        </div>
        <div class="col-xs-2">
          <router-link :to="{ name: 'AddBanner' }" class="btn btn-sm btn-add">
            <i class="fa fa-plus-square"></i>Create New Banner
          </router-link>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-2 form-group">
            <input
              placeholder="Banner Name"
              type="text"
              v-model="banner_name"
              name="banner_name"
              id="banner_name"
              class="form-control input-sm"
              value
              autocomplete="off"
            />
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title
              name="user_type"
              id="user_type"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="user_type"
              v-select="user_type"
            >
              <option value>-- Select User Type--</option>
              <option value="1">Reseller</option>
              <option value="2">Premium</option>
              <option value="3">Free</option>
            </select>
          </div>

          <div class="col-xs-6 col-sm-2 form-group">
            <select
              title
              name="status"
              id="status"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="status"
              v-select="status"
            >
              <option value>-- All Status --</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </div>

          <div class="col-xs-6 col-sm-1 form-group" style="margin-bottom: 0">
            <select
              title
              name="limit"
              id="limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
              v-model="display_limit"
              v-select="display_limit"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100" selected>100</option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-1 ZRP form-group btnLeft">
            <button
              class="btn btn-sm btn-submit"
              id="apply"
              @click="dopagination(0)"
            >
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box" id="pagination">
            <div class="box-header">
              <h3 class="box-title">
                Total : {{ totalData }} Showing {{ dispplayFrom }} to
                {{ dispplayTo }}
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table
                  class="table table-0024 table-bordered table-striped table-scroll"
                >
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Banner Name</th>
                      <th class="tdCenter" width="300">Android Banner</th>
                      <th class="tdCenter" width="300">IOS Banner</th>
                      <th class="tdCenter">Is Free</th>
                      <th class="tdCenter">Is Premium</th>
                      <th class="tdCenter">Is Reseller</th>
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataCollections" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(sl) + parseInt(index) }}
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{ name: 'EditBanner', params: { id: data.id } }"
                          :title="data.banner_name"
                          >{{ data.banner_name }}</router-link
                        >
                      </td>
                      <td class="tdCenter" width="300">
                        <img
                          class="img-thumbnail"
                          :src="data.android_banner_img"
                          v-if="data.android_banner_img"
                        />
                      </td>
                      <td class="tdCenter" width="300">
                        <img
                          class="img-thumbnail"
                          :src="data.ios_banner_img"
                          v-if="data.ios_banner_img"
                        />
                      </td>
                      <td class="tdCenter">
                        <small v-if="com_CheckUserType(data.user_type, 3)"
                          >Yes</small
                        >
                        <small v-else>No</small>
                      </td>
                      <td class="tdCenter">
                        <small v-if="com_CheckUserType(data.user_type, 2)"
                          >Yes</small
                        >
                        <small v-else>No</small>
                      </td>
                      <td class="tdCenter">
                        <small v-if="com_CheckUserType(data.user_type, 1)"
                          >Yes</small
                        >
                        <small v-else>No</small>
                      </td>
                      <td class="tdCenter">
                        <span v-if="data.status == 1">
                          <label class="label label-success">Active</label>
                        </span>
                        <span v-else>
                          <label class="label label-danger">Inactive</label>
                        </span>
                      </td>
                      <td class="tdCenter">
                        <router-link
                          :to="{ name: 'EditBanner', params: { id: data.id } }"
                          title="Edit"
                        >
                          <i class="ion ion-ios-compose table-edit"></i>
                        </router-link>
                      </td>
                    </tr>
                    <tr v-if="dataCollections.length == 0">
                      <td colspan="13" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                  >« Previous</a
                >
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">{{
                        index
                      }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">{{
                        index
                      }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted"
                        >..</span
                      >
                      <span class="current" v-if="index == current_page">{{
                        index
                      }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">{{
                      index
                    }}</span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a
                  v-if="next_page_url"
                  href="#"
                  @click="dopagination(nextPage)"
                  class="next"
                  >Next »</a
                >
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "BannerLists",
  data() {
    return {
      dataCollections: [],
      resellerArr: [],
      banner_name: "",
      status: "",
      user_type: "",
      sl: 1,
      display_limit: 100,
      alert_message: "",
      totalData: 0,
      dispplayTo: 0,
      dispplayFrom: 0,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    this.dopagination(0);
  },
  methods: {
    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;
      let querystr =
        "banner_name=" +
        this.banner_name +
        "&status=" +
        this.status +
        "&user_type=" +
        this.user_type;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("ads-banner?" + querystr)
        .then(function (response) {
          //console.log(response);
          rootObject.populateData(response);
        })
        .catch(function (error) {
          console.log(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateData(response) {
      var responseData = response.data;
      //console.log(responseData.data);
      this.sl = parseInt(responseData.from);
      this.dataCollections = responseData.data;
      this.totalData = responseData.total;
      this.dispplayFrom = responseData.from;
      this.dispplayTo = responseData.to;
      this.display_limit = responseData.per_page;
      this.paginationGenerate(responseData);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.prev_page_url;
      this.next_page_url = response.next_page_url;
      this.last_page = response.last_page;
      this.current_page = response.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.red {
  color: red;
}
.img-thumbnail {
  width: 100%;
}
</style>
