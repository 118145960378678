<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-8" align="left">
          <h1>Search All Resellers Client</h1>
          <br />
          <span v-if="userData.user_type == 1">
            *** Total Reseller: {{ totalResellerCreated }}
          </span>
          <span v-else>
            <span v-if="userData.user_level == 4">
              *** Total Reseller: {{ totalResellerCreated }}/ {{ limit_user }}
            </span>
            <span v-else>
              *** Total Reseller: {{ totalResellerCreated + totalData }}/
              {{ limit_user }}
            </span>
          </span>
        </div>
        <div class="col-xs-4">
          <router-link
            :to="{ name: 'AddNewResellers' }"
            class="btn btn-sm btn-add"
            id="reseller-add-form-btn"
          >
            <i class="fa fa-plus-square"></i>
            Add New Reseller
          </router-link>
          <a class="btn btn-sm btn-add" @click="RefreshNow">
            <i class="fa fa-refresh"></i>Refresh
          </a>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-3 form-group">
            <input
              placeholder="Login"
              type="text"
              name="login"
              v-model="login"
              id="login"
              class="form-control input-sm"
              value=""
            />
          </div>
          <div class="col-xs-6 col-sm-3 form-group">
            <input
              placeholder="Name"
              type="text"
              name="full_name"
              v-model="full_name"
              id="full_name"
              class="form-control input-sm"
              value=""
            />
          </div>
          <div class="col-xs-6 col-sm-3 form-group">
            <select
              title=""
              name="status"
              v-model="status"
              v-select="status"
              id="status"
              class="form-control input-sm select2"
              @change="dopagination(0)"
            >
              <option value="">All</option>
              <option value="1">Active Reseller</option>
              <option value="0">Inactive Reseller</option>
            </select>
          </div>
          <div class="col-xs-3 col-sm-1 form-group">
            <select
              title=""
              name="limit"
              id="limit"
              v-model="display_limitt"
              v-select="display_limitt"
              class="form-control input-sm select2"
              @change="dopagination(0)"
            >
              <option value="25">25</option>
              <option value="50" selected>50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-xs-2 form-group">
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">
                Total : {{ totalData }}
                <span v-if="dispplayFrom">
                  Showing {{ dispplayFrom }} to {{ dispplayTo }}
                </span>
                <span class="pull-right noOfData">
                  {{ totalData }}
                </span>
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN" rowspan="2">SN</th>
                      <th rowspan="2">Login</th>
                      <th rowspan="2">Password</th>
                      <th rowspan="2">Name</th>
                      <th rowspan="2">Phone</th>
                      <th rowspan="2">Created At</th>
                      <th rowspan="2">VPN Current Balance</th>
                      <th rowspan="2">Level</th>
                      <th colspan="3" class="tdCenter">Reseller Level</th>
                      <th class="tdCenter" rowspan="2">Status</th>
                    </tr>
                    <tr>
                      <th>Level 4</th>
                      <th>Level 3</th>
                      <th>Level 2</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(reseller, index) in resellerArr"
                      :key="index + 1"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ reseller.login }}</td>
                      <td>{{ reseller.password }}</td>
                      <td>{{ reseller.full_name }}</td>
                      <td>{{ reseller.phone }}</td>
                      <td>{{ reseller.creationdate }}</td>
                      <td>{{ reseller.balance }}</td>
                      <td class="tdCenter">{{ reseller.level }}</td>
                      <td v-if="reseller.under_resellers_level.length > 0">
                        {{ reseller.under_resellers_level[0].reseller4_name }}
                      </td>
                      <td v-else></td>
                      <td v-if="reseller.under_resellers_level.length > 0">
                        {{ reseller.under_resellers_level[0].reseller3_name }}
                      </td>
                      <td v-else></td>
                      <td v-if="reseller.under_resellers_level.length > 0">
                        {{ reseller.under_resellers_level[0].reseller2_name }}
                      </td>
                      <td v-else></td>
                      <td class="tdCenter" v-if="reseller.status == 1">
                        <span class="label label-success">Active</span>
                      </td>
                      <td class="tdCenter" v-else>
                        <span class="label label-danger">Inactive</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                >
                  « Previous
                </a>
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted">
                        ..
                      </span>
                      <span class="current" v-if="index == current_page">
                        {{ index }}
                      </span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">
                          {{ index }}
                        </a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">
                      {{ index }}
                    </span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a
                  v-if="next_page_url"
                  href="#"
                  @click="dopagination(nextPage)"
                  class="next"
                >
                  Next »
                </a>
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ResellerList",
  data() {
    return {
      totalResellerCreated: 0,
      limit_user: 0,
      resellerArr: {},
      login: "",
      full_name: "",
      status: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      display_limitt: 50,
      payment_history: {},
      payment_reseller: {},
      payment_rate_plan_details: {},
      no_of_pins: "",
      amount: "",
      pay_amount: "",
      type: 1,
      creationdate: "",
      description: "",
      alert_message: "",
      balance_alert_limit: 10,
      userData: "",
      slNo: 1,

      error_no_of_pins: false,
      error_amount: false,
      error_description: false,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    if (userData.user_level == 1) {
      this.$router.push("dashboard");
    }

    this.dopagination(0);
  },
  methods: {
    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;
      rootObject.creationdate = this.com_currentDateTime();
      let querystr =
        "login=" +
        this.login +
        "&full_name=" +
        this.full_name +
        "&status=" +
        this.status +
        "&paginate=" +
        this.display_limitt;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("allresellers?" + querystr)
        .then(function (response) {
          rootObject.populateResellerData(response);
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          console.log("error.response.data", error.response.data);
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateResellerData(response) {
      this.resellerArr = response.data.data;
      this.totalData = response.data.total;
      this.dispplayFrom = response.data.from;
      this.dispplayTo = response.data.to;
      this.display_limit = response.data.per_page;

      this.totalResellerCreated = response.data.total;
      this.limit_user = response.data.limit_user;

      this.paginationGenerate(response);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.data.data.prev_page_url;
      this.next_page_url = response.data.data.next_page_url;
      this.last_page = response.data.data.last_page;
      this.current_page = response.data.data.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },

    RefreshNow() {
      this.login = "";
      this.full_name = "";
      this.status = 1;
      this.display_limit = 50;
      this.dopagination(0);
    },
  },
  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
