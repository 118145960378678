<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>Add New Subscription Batch</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right"> Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div id="res_message" style="display: none" class="alert"></div>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-sm-6">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Basic Configuration</h3>
            </div>
            <div class="box-body form-box-body form-horizontal">
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Batch Name <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <input
                    name="description"
                    id="description"
                    class="form-control input-sm stop-specialchar"
                    v-model="description"
                    value=""
                    placeholder="Batch Name"
                    type="text"
                    onfocus="this.removeAttribute('readonly');"
                    readonly=""
                  />
                  <label
                    v-if="error_description"
                    class="error"
                    for="description"
                    >{{ error_description }}</label
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Bundle <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <select
                    title=""
                    name="rate_plan_id"
                    id="rate_plan_id"
                    class="form-control input-sm select2"
                    onfocus="this.removeAttribute('readonly');"
                    v-model="rate_plan_id"
                    v-select="rate_plan_id"
                    @change="loadRatePlans"
                  >
                    <option value="">Select Bundle</option>
                    <option
                      v-for="data in bundles"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.name }}
                    </option>
                  </select>
                  <label
                    v-if="error_rate_plan_id"
                    class="error"
                    for="rate_plan_id"
                    >{{ error_rate_plan_id }}</label
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Rate Plan<span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <select
                    title=""
                    name="plan_id"
                    id="plan_id"
                    class="form-control input-sm select2"
                    v-model="plan_id"
                    onfocus="this.removeAttribute('readonly');"
                    v-select="plan_id"
                  >
                    <option value="">Select Rate Plan</option>
                    <option
                      v-for="data in rateplans"
                      :key="data.id"
                      :value="data.id"
                    >
                      {{ data.plan_name }}
                    </option>
                  </select>
                  <label v-if="error_plan_id" class="error" for="plan_id">{{
                    error_plan_id
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">Advanced Configuration</h3>
            </div>
            <div class="box-body form-box-body form-horizontal">
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Total Number of Subscribers
                  <span class="mandatory_horizontal">*</span></label
                >
                <div class="col-md-8">
                  <input
                    name="numClients"
                    id="numClients"
                    class="form-control input-sm only_number"
                    v-model="numClients"
                    value="100"
                    placeholder="Total Number of Subscriber "
                    type="text"
                    onfocus="this.removeAttribute('readonly');"
                    readonly=""
                  />
                  <label
                    v-if="error_numClients"
                    class="error"
                    for="numClients"
                    >{{ error_numClients }}</label
                  >
                  <label class="field_suggestion"
                    >How many <b>Subscriber</b> will be <b>CREATED</b>.</label
                  >
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label">Subscribers Length</label>
                <div class="col-md-8">
                  <select
                    title=""
                    name="loginLength"
                    v-model="loginLength"
                    id="loginLength"
                    class="form-control input-sm select2"
                    v-select="loginLength"
                  >
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Subscription Prefix</label
                >
                <div class="col-md-8">
                  <input
                    name="loginPrefix"
                    id="loginPrefix"
                    class="form-control input-sm only_number"
                    placeholder="Subscription Prefix"
                    type="text"
                    onfocus="this.removeAttribute('readonly');"
                    @keyup="checkLeadingZero(1)"
                    v-model="loginPrefix"
                    maxlength="2"
                  />
                  <label class="field_suggestion"
                    >1. within Subscription Length.</label
                  >
                  <label class="field_suggestion"
                    >2. Only Numeric Prefix Allowed.</label
                  >
                  <label class="field_suggestion"
                    >3. 0 Prefix Not Allowed at first.</label
                  >
                </div>
              </div>

              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Subscribers Password Length</label
                >
                <div class="col-md-8">
                  <select
                    title=""
                    name="passwordLength"
                    id="passwordLength"
                    class="form-control input-sm select2"
                    v-model="passwordLength"
                    v-select="passwordLength"
                  >
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                </div>
              </div>
              <div class="form-group">
                <label class="col-md-4 control-label"
                  >Subscribers Password Prefix</label
                >
                <div class="col-md-8">
                  <input
                    name="passwordPrefix"
                    id="passwordPrefix"
                    class="form-control input-sm only_number"
                    placeholder="Subscribers Password Prefix"
                    type="text"
                    maxlength="2"
                    onfocus="this.removeAttribute('readonly');"
                    @keyup="checkLeadingZero(2)"
                    v-model="passwordPrefix"
                  />
                  <label class="field_suggestion"
                    >1. within Subscribers Password Length.</label
                  >
                  <label class="field_suggestion"
                    >2. Only Numeric Prefix Allowed.</label
                  >
                  <label class="field_suggestion"
                    >3. 0 Prefix Not Allowed at first.</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 btnCenter">
          <input
            type="submit"
            name="submit"
            @click="formSubmit"
            id="send_form"
            value="Generate"
            class="btn btn-sm btn-submit btn-submit-big"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddPinBatch",
  data() {
    return {
      userData: {},
      bundles: {},
      rateplans: {},
      description: "",
      rate_plan_id: "",
      plan_id: "",
      numClients: "100",
      loginLength: 8,
      passwordLength: 8,
      loginPrefix: "",
      passwordPrefix: "",
      alert_message: "",

      error_description: false,
      error_rate_plan_id: false,
      error_plan_id: false,
      error_numClients: false,
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    this.userData = JSON.parse(decodedString);

    var rootObject = this;
    loader.show();
    axios
      .get("rate-plan-dropdown-data")
      .then(function (response) {
        rootObject.bundles = response.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });
  },
  methods: {
    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;

      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_description = false;
          rootObject.error_rate_plan_id = false;
          rootObject.error_plan_id = false;
          rootObject.error_numClients = false;
        }, 5000);
        return 0;
      }
      loader.show();
      const form = new FormData();
      form.append("description", this.description);
      form.append("rate_plan_id", this.rate_plan_id);
      form.append("plan_id", this.plan_id);
      form.append("numClients", this.numClients);
      form.append("loginLength", this.loginLength);
      form.append("passwordLength", this.passwordLength);
      if (this.loginPrefix) {
        form.append("loginPrefix", this.loginPrefix);
      }
      form.append("passwordPrefix", this.passwordPrefix);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("batch-pin-generate", form, config)
        .then(function (response) {
          rootObject.alert_message =
            "New Subscribers Batch Successfully Created";
          rootObject.description = "";
          rootObject.rate_plan_id = "";
          rootObject.plan_id = "";
          rootObject.numClients = "";
          loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          rootObject.com_errorHandel(error);
        });
      setTimeout(function () {
        rootObject.alert_message = "";
        rootObject.error_description = false;
        rootObject.error_rate_plan_id = false;
        rootObject.error_plan_id = false;
        rootObject.error_numClients = false;
      }, 5000);
    },

    loadRatePlans() {
      var rootObject = this;

      if (this.rate_plan_id) {
        loader.show();
        axios
          .get("get-rates-by-plan-id/" + this.rate_plan_id)
          .then(function (response) {
            rootObject.rateplans = response.data.rates;
            loader.hide();
          })
          .catch(function (error) {
            let alertmessage = "";
            for (var prop in error.response.data) {
              alertmessage += " " + error.response.data[prop];
            }
            console.log(alertmessage);
            loader.hide();
            rootObject.com_errorHandel(error);
          });
        rootObject.plan_id = "";
      } else {
        rootObject.rateplans = {};
        rootObject.plan_id = "";
      }
    },

    checkLeadingZero(field_no) {
      var val = "";
      if (field_no == 1) {
        val = this.loginPrefix;
      }

      if (field_no == 2) {
        val = this.passwordPrefix;
      }
      var firstDigits = ("" + val).split("")[0];
      if (firstDigits == "0") {
        $.alert("Please, Do not use Zero (0) as a first letter for this field");
        if (field_no == 1) {
          this.loginPrefix = "";
        }
        if (field_no == 2) {
          this.passwordPrefix = "";
        }
      }
    },

    fromValidation(reset = false) {
      var has_error = false;
      var error_description = false;
      var error_rate_plan_id = false;
      var error_plan_id = false;
      var error_numClients = false;

      if (!this.description) {
        this.error_description = "This field is required";
        has_error = true;
      }

      if (!this.rate_plan_id) {
        this.error_rate_plan_id = "This field is required";
        has_error = true;
      }

      if (!this.plan_id) {
        this.error_plan_id = "This field is required";
        has_error = true;
      }

      if (!this.numClients) {
        this.error_numClients = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });
    $(".stop-specialchar").keypress(function (e) {
      var keyCode = e.keyCode || e.which;
      var regexp = new RegExp("^[a-zA-Z0-9_ ]+$");
      var result = regexp.test(String.fromCharCode(keyCode));
      if (!result) {
        return false;
      }
    });
    $(".only_number").keypress(function (e) {
      var keyCode = e.keyCode || e.which;
      var regexp = new RegExp("^[0-9]+$");
      var result = regexp.test(String.fromCharCode(keyCode));
      if (!result) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
