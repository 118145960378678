import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
  	isLogin : false,
  	user : null,
    panel: null,
    panelData: {},
    serverWSData: {},
    wsReloaded: true,
    wsConnectOpenStatus: false,
    wsConnections: {},
    dsWsConnectOpenStatus: false,
    dsWsConnections: {},
    wsServerLoadConnection: {},
  },
  getters: {
    getLoginStatus: state => {
      return state.isLogin;
    },
    getUser: state => {
      return state.user;
    },
    getPanel: state => {
      return state.panel;
    },
    getPanelData: state => {
      return state.panelData;
    },
    getServerWSData: state => {
      return state.serverWSData;
    },
    getWsReloaded: state => {
      return state.wsReloaded;
    },
    getWsConnectOpenStatus: state => {
      return state.wsConnectOpenStatus;
    },
    getWsOpenConnections: state => {
      return state.wsConnections;
    },
    getWsServerLoadConnection: state => {
      return state.wsServerLoadConnection;
    },
    getDsWsConnectionStatus: state => {
      return state.dsWsConnectOpenStatus;
    },
    getDsWsConnections: state => {
      return state.dsWsConnections;
    }
  },
  mutations: {
  	channgeIsLoginState(state, payload){
		  state.isLogin = payload;
	  },
    channgeUserState(state, payload){
      state.user = payload;
    },
    changePanel(state, payload){
      state.panel = payload;
    },
    changePanelData(state, payload){
      state.panelData = payload;
    },
    changeServerWSData(state, payload){
      state.serverWSData = payload;
    },
    changeWsReloaded(state, payload){
      state.wsReloaded = payload;
    },
    changeWsConnectOpenStatus(state, payload){
      state.wsConnectOpenStatus = payload;
    },
    changeWsOpenConnections(state, payload){
      state.wsConnections = payload;
    },
    changeWsServerLoadConnection(state, payload){
      state.wsServerLoadConnection = payload;
    },
    changeDsWsConnectionStatus(state, payload){
      state.dsWsConnectOpenStatus = payload;
    },
    changeDsWsConnections(state, payload){
      state.dsWsConnections = payload;
    },
  },
  actions: {
  	changLoginStatus(context, payload){
		  context.commit('channgeIsLoginState', payload);
	  },
    setUser(context, payload){
      context.commit('channgeUserState', payload);
    },
    setPanel(context, payload){
      context.commit('changePanel', payload);
    },
    setPanelData(context, payload){
      context.commit('changePanelData', payload);
    },
    setServerWSData(context, payload){
      context.commit('changeServerWSData', payload);
    },
    setWsReloaded(context, payload){
      context.commit('changeWsReloaded', payload);
    },
    setWsConnectOpenStatus(context, payload){
      context.commit('changeWsConnectOpenStatus', payload);
    },
    setWsOpenConnections(context, payload){
      context.commit('changeWsOpenConnections', payload);
    },
    setWsServerLoadConnection(context, payload){
      context.commit('changeWsServerLoadConnection', payload);
    },
    setDsWsConnectionStatus(context, payload){
      context.commit('changeDsWsConnectionStatus', payload);
    },
    setDsWsConnection(context, payload){
      context.commit('changeDsWsConnections', payload);
    },
  },
  modules: {},
});
