<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-6">
          <h1>{{ LabelFormName }} IP</h1>
        </div>
        <div class="col-xs-6 mandatoryDiv">
          <span class="mandatory_color">(*)</span>
          <span class="letter-right">Marked are required field</span>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <form role="form">
          <div class="col-sm-6">
            <div class="box">
              <div class="box-header">
                <h3 class="box-title">Basic Serverip Information</h3>
              </div>
              <div class="box-body form-box-body form-horizontal">
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    Server Name
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="name"
                      id="name"
                      class="form-control input-sm"
                      v-model="name"
                      value
                      placeholder="name"
                      type="text"
                    />
                    <label v-if="error_name" class="error" for="name">
                      {{ error_name }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    IP
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <input
                      name="ip"
                      v-model="ip"
                      id="ip"
                      class="form-control input-sm"
                      value
                      placeholder="Server IP"
                      type="text"
                    />
                    <label class="field_suggestion">
                      <i
                        >Port for OpenVPN TCP -443, OpenVPN UDP -1194, open
                        connect-4439 & Wireguard -51820</i
                      >
                    </label>
                    <label v-if="error_ip" class="error" for="name">
                      {{ error_ip }}
                    </label>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Note</label>
                  <div class="col-md-8">
                    <textarea v-model="note" class="form-control"></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Type</label>
                  <div class="col-md-8">
                    <select
                      title
                      name="type"
                      id="type"
                      class="form-control input-sm select2"
                      v-model="type"
                      v-select="type"
                      @change="protocolChange()"
                    >
                      <option value="1">Open VPN UDP</option>
                      <option value="6">Open VPN TCP</option>
                      <option value="2">Open Connect</option>
                      <option value="5">WireGuard</option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Country</label>
                  <div class="col-md-8">
                    <select
                      title
                      name="country_code3"
                      id="country_code3"
                      class="form-control input-sm select2"
                      v-model="country_code3"
                      v-select="country_code3"
                    >
                      <option value>Select Country</option>
                      <option
                        v-for="(item, index) in countries"
                        :key="index"
                        :value="index"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">
                    VPN Server
                    <span class="mandatory_horizontal">*</span>
                  </label>
                  <div class="col-md-8">
                    <select
                      title
                      name="vpn_server_id"
                      id="vpn_server_id"
                      class="form-control input-sm select2"
                      v-model="vpn_server_id"
                      v-select="vpn_server_id"
                    >
                      <option value>Select Server</option>
                      <option
                        v-for="(item, index) in servers"
                        :key="index"
                        :value="index"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <label
                      v-if="error_vpn_server_id"
                      class="error"
                      for="name"
                      >{{ error_vpn_server_id }}</label
                    >
                  </div>
                </div>
                <div class="form-group" v-if="this.type == 5" >
                  <label class="col-md-4 control-label">Config</label>
                  <div class="col-md-8">
                    <textarea class="form-control" v-model="config"></textarea>
                  </div>
                </div>
                <div class="form-group">
                  <label class="col-md-4 control-label">Platform</label>
                  <div class="col-md-8">
                    <select
                      title
                      name="platform"
                      id="platform"
                      class="form-control input-sm select2"
                      v-model="platform"
                      v-select="platform"
                    >
                      <option value="all">All</option>
                      <option value="android">Android</option>
                      <option value="ios">Ios</option>
                    </select>
                  </div>
                </div>

                <div class="col-md-12">
                  <br />
                </div>

                <div class="col-md-3 border-left border-right">
                  <div class="form-group">
                    <label class="col-md-9 control-label">Is Active</label>
                    <div class="col-md-3">
                      <input
                        name="status"
                        type="checkbox"
                        v-model="status"
                        id="status"
                        checked="checked"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-3 border-left border-right">
                  <div class="form-group">
                    <label class="col-md-9 control-label">Is Free</label>
                    <div class="col-md-3">
                      <input
                        name="is_free"
                        type="checkbox"
                        v-model="is_free"
                        id="is_free"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-3 border-left border-right">
                  <div class="form-group">
                    <label class="col-md-9 control-label"
                      >Is Higspeed server</label
                    >
                    <div class="col-md-3">
                      <input
                        name="is_fast"
                        type="checkbox"
                        v-model="is_fast"
                        id="is_fast"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-3 border-left border-right">
                  <div class="form-group">
                    <label class="col-md-9 control-label">Is Streaming</label>
                    <div class="col-md-3">
                      <input
                        name="is_streaming"
                        type="checkbox"
                        v-model="is_streaming"
                        id="is_streaming"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4 border-left border-right">
                  <div class="form-group">
                    <label class="col-md-9 control-label"
                      >Is Gaming Server</label
                    >
                    <div class="col-md-3">
                      <input
                        name="is_gaming"
                        type="checkbox"
                        v-model="is_gaming"
                        id="is_gaming"
                      />
                    </div>
                  </div>
                </div>

                <div class="col-md-4 border-left border-right">
                  <div class="form-group">
                    <label class="col-md-9 control-label"
                      >Is Ad Blocked Sever</label
                    >
                    <div class="col-md-3">
                      <input
                        name="is_adblocker"
                        type="checkbox"
                        v-model="is_adblocker"
                        id="is_adblocker"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 btnCenter">
            <input
              type="submit"
              name="submit"
              value="Submit"
              class="btn btn-sm btn-submit btn-submit-big"
              @click="formSubmit"
            />
          </div>
        </form>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "AddServers",
  data() {
    return {
      id: "",
      name: "",
      ip: "",
      note: "",
      type: 1,
      vpn_server_id: "",
      country_code3: "",
      config: "",
      platform: "all",
      status: true,
      is_fast: false,
      is_free: false,
      is_streaming: false,
      is_gaming: false,
      is_adblocker: false,

      error_name: false,
      error_ip: false,
      error_vpn_server_id: false,
      isEditing: false,
      alert_message: "",
      LabelFormName: "Add New",
      servers: {},
      countries: {},
    };
  },
  created() {
    var rootObject = this;
    //Load Vpn Servers
    axios
      .post("get-vpnserver-names")
      .then(function (response) {
        //console.log(response.data);
        rootObject.servers = response.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });

    if (this.$route.params.id) {
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }

    //Load Country
    axios
      .get("get-ip-countries")
      .then(function (response) {
        //console.log(response.data);
        rootObject.countries = response.data;
        loader.hide();
      })
      .catch(function (error) {
        let alertmessage = "";
        for (var prop in error.response.data) {
          alertmessage += " " + error.response.data[prop];
        }
        console.log(alertmessage);
        loader.hide();
        rootObject.com_errorHandel(error);
      });

    if (this.$route.params.id) {
      rootObject.isEditing = true;
      rootObject.id = this.$route.params.id;
      rootObject.getEditData();
    }
  },
  methods: {
    getEditData() {
      loader.show();
      let rootObject = this;
      rootObject.LabelFormName = "Edit";
      axios
        .get("ips/" + rootObject.id)
        .then(function (response) {
          console.log(response.data.vpn_server_id);
          rootObject.name = response.data.name;
          rootObject.ip = response.data.ip;
          rootObject.note = response.data.note;
          rootObject.type = !response.data.type ? "" : response.data.type;
          if (response.data.vpn_server_id) {
            rootObject.vpn_server_id = response.data.vpn_server_id;
          }
          rootObject.country_code3 = !response.data.country_code3
            ? ""
            : response.data.country_code3;
          rootObject.config = response.data.config;
          rootObject.platform = response.data.platform;
          rootObject.status = response.data.status === 1 ? true : false;
          rootObject.is_streaming =
            response.data.is_online_stream === 1 ? true : false;
          rootObject.is_free = response.data.is_free === 1 ? true : false;
          rootObject.is_fast =
            response.data.is_fast_server === 1 ? true : false;

          rootObject.is_gaming = response.data.is_gaming === 1 ? true : false;
          rootObject.is_adblocker =
            response.data.is_adblocker === 1 ? true : false;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    protocolChange(){
      this.config="";
      if(this.type == 5){
        this.config = '{"DNS":"8.8.8.8, 8.8.4.4", "PersistentKeepalive":"25", "AllowedIPs":"0.0.0.0/0, ::/0"}';
      }
    },

    formSubmit(e) {
      e.preventDefault();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        setTimeout(function () {
          rootObject.error_name = false;
          rootObject.error_ip = false;
          rootObject.error_vpn_server_id = false;
        }, 5000);
        return 0;
      }
      let status = this.status === true ? 1 : 2;
      let is_online_stream = this.is_streaming === true ? 1 : 0;
      let is_free = this.is_free === true ? 1 : 0;
      let is_gaming = this.is_gaming === true ? 1 : 0;
      let is_adblocker = this.is_adblocker === true ? 1 : 0;
      let is_fast_server = this.is_fast === true ? 1 : 0;

      loader.show();
      let querystr = "";
      const form = new FormData();
      form.append("name", this.name);
      form.append("ip", this.ip);
      form.append("note", this.note);
      form.append("type", this.type);
      form.append("vpn_server_id", this.vpn_server_id);
      form.append("country_code3", this.country_code3);
      form.append("config", this.config);
      form.append("platform", this.platform);
      form.append("status", status);
      form.append("is_online_stream", is_online_stream);
      form.append("is_free", is_free);
      form.append("is_gaming", is_gaming);
      form.append("is_adblocker", is_adblocker);
      form.append("is_fast_server", is_fast_server);

      if (rootObject.isEditing) {
        form.append("_method", "put");
        querystr = "/" + rootObject.id;
      }
      //console.log(querystr)

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("ips" + querystr, form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message = response.data.message;
          if (!rootObject.isEditing) {
            (rootObject.name = ""),
              (rootObject.ip = ""),
              (rootObject.note = ""),
              (rootObject.type = ""),
              (rootObject.vpn_server_id = ""),
              (rootObject.country_code3 = ""),
              (rootObject.config = ""),
              (rootObject.platform = "all"),
              (rootObject.status = true);
          }
          rootObject.loader.hide();
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          rootObject.alert_message = alertmessage;
        });

      setTimeout(function () {
        rootObject.alert_message = "";
      }, 5000);
    },

    fromValidation(reset = false) {
      var has_error = false;
      if (!this.name) {
        this.error_name = "This field is required";
        has_error = true;
      }

      if (!this.ip) {
        this.error_ip = "This field is required";
        has_error = true;
      }

      if (!this.vpn_server_id) {
        this.error_vpn_server_id = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
