<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-10">
          <h1>VPN Server Bunlde : {{ ip_bundle_name }}</h1>
        </div>
        <div class="col-xs-2">
          <a class="btn btn-sm btn-add colorbox" @click="addNewIpToBundle()">
            <i class="fa fa-plus-square"></i>Add New Server to Bundle
          </a>
        </div>
      </div>
    </section>
    <section class="content">
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">
            {{ alert_message }}
          </div>
        </div>
        <div class="col-md-12">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">
                Total {{ dataCollections.length }} rows found.
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th class="tdCenter">Name</th>
                      <th class="tdCenter">IP</th>
                      <th class="tdCenter">Priority</th>
                      <th class="tdCenter">Type</th>
                      <th class="tdCenter">Status</th>
                      <th class="tdCenter">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataCollections" :key="data.id">
                      <td class="tdCenter tdSN">
                        {{ parseInt(sl) + parseInt(index) }}
                      </td>
                      <td class="tdCenter">
                        {{ data.ip_name }}
                      </td>
                      <td class="tdCenter">
                        {{ data.ip }}
                      </td>
                      <td
                        class="tdCenter"
                        @dblclick="contentEdit(data.id)"
                        @blur="updatePriority(data.id)"
                        :original-value="data.priority"
                        :id="'priority-' + data.id"
                      >
                        {{ data.priority }}
                      </td>
                      <td class="tdCenter">
                        <span class="label label-success" v-if="data.type == 1"
                          >Open VPN UDP</span
                        >
                        <span class="label label-danger" v-if="data.type == 5"
                          >WireGuard</span
                        >
                        <span class="label label-warning" v-if="data.type == 6"
                          >Open VPN TCP</span
                        >
                        <span class="label label-primary" v-if="data.type == 4"
                          >ShadowSocks</span
                        >
                        <span class="label label-primary" v-if="data.type == 2"
                          >Open Connect</span
                        >
                      </td>
                      <td class="tdCenter">
                        <span
                          class="label label-success"
                          v-if="data.status == 1"
                          >Active</span
                        >
                        <span class="label label-danger" v-else>Inctive</span>
                      </td>
                      <td class="tdCenter">
                        <a @click="contentEdit(data.id)" title="Edit">
                          <i class="ion ion-ios-compose table-edit"></i>
                        </a>

                        <a @click="removeData(data)"
                          ><i class="ion ion-ios-trash table-delete"></i
                        ></a>
                      </td>
                    </tr>
                    <tr v-if="dataCollections.length == 0">
                      <td colspan="7" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>

          <div style="display: none">
            <div id="IPListBox">
              <div class="box" style="padding: 20px">
                <div class="row">
                  <div class="col-xs-6 col-sm-2 form-group">
                    <input
                      placeholder="Name"
                      type="text"
                      name="name"
                      v-model="name"
                      id="name"
                      class="form-control input-sm input2"
                      value=""
                    />
                  </div>
                  <div class="col-xs-6 col-sm-2 form-group">
                    <input
                      placeholder="Search IP"
                      type="text"
                      v-model="ip"
                      name="ip"
                      id="ip"
                      class="form-control input-sm input2"
                    />
                  </div>
                  <div class="col-xs-6 col-sm-2 form-group">
                    <input
                      placeholder="Note"
                      type="text"
                      v-model="note"
                      name="note"
                      id="note"
                      class="form-control input-sm input2"
                    />
                  </div>
                  <div class="col-xs-6 col-sm-2 form-group">
                    <select
                      name="type"
                      id="type"
                      class="form-control input-sm"
                      v-model="type"
                      @change="dopagination(0)"
                    >
                      <option value="">Filter Type</option>
                      <option value="1">Open VPN UDP</option>
                      <option value="6">Open VPN TCP</option>
                      <option value="2">Open Connect</option>
                      <option value="5">Wireguard</option>
                    </select>
                  </div>
                  <div class="col-xs-6 col-sm-2 form-group">
                    <select
                      name="status"
                      id="status"
                      class="form-control input-sm"
                      v-model="status"
                      @change="dopagination(0)"
                    >
                      <option value="">Filter Status</option>
                      <option value="1">Active</option>
                      <option value="2">Inactive</option>
                    </select>
                  </div>

                  <div class="col-xs-6 col-sm-1 form-group">
                    <select
                      title=""
                      name="limit"
                      id="limit"
                      v-model="display_limit"
                      class="form-control input-sm"
                      @change="dopagination(0)"
                    >
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="75">75</option>
                      <option value="100">100</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>

                  <div class="col-xs-6 col-sm-1 ZRP form-group pull-right">
                    <a
                      id="search2"
                      class="btn btn-sm btn-search btnRight"
                      href="#"
                      @click="dopagination(0)"
                    >
                      <i class="fa fa-search"></i> Search
                    </a>
                  </div>
                </div>

                <div class="box-header">
                  <h3 class="box-title">
                    <b>Add Server to Bundle : {{ ip_bundle_name }}</b>
                  </h3>
                </div>
                <div class="box-body">
                  <div class="table-responsive">
                    <table
                      class="table table-0024 table-bordered table-striped table-scroll"
                    >
                      <thead>
                        <tr>
                          <th class="tdCenter tdSN">SN</th>
                          <th class="tdCenter">Name</th>
                          <th class="tdCenter">IP</th>
                          <th class="tdCenter">Note</th>
                          <th class="tdCenter">Type</th>
                          <th class="tdCenter">Action</th>
                        </tr>
                      </thead>
                      <tbody class="body-half-screen">
                        <tr
                          style="cursor: copy"
                          v-for="(ipData, index) in ipList"
                          :key="ipData.id"
                          @click="loadIPInformation(ipData)"
                        >
                          <td class="tdCenter tdSN">
                            {{ parseInt(sl) + index }}
                          </td>
                          <td class="tdCenter">{{ ipData.name }}</td>
                          <td class="tdCenter">{{ ipData.ip }}</td>
                          <td class="tdCenter">{{ ipData.note }}</td>
                          <td class="tdCenter">
                            <span
                              class="label label-success"
                              v-if="ipData.type == 1"
                              >Open VPN UDP</span
                            >
                            <span
                              class="label label-warning"
                              v-if="ipData.type == 6"
                              >Open VPN TCP</span
                            >
                            <span
                              class="label label-primary"
                              v-if="ipData.type == 2"
                              >Open Connect</span
                            >
                            <span
                              class="label label-danger"
                              v-if="ipData.type == 5"
                              >WireGuard</span
                            >
                          </td>
                          <td class="tdCenter">
                            <a title="Set IP">
                              <i class="ion ion-ios-checkmark table-edit"> </i>
                              Select
                            </a>
                          </td>
                        </tr>
                        <tr v-if="ipList.length == 0">
                          <td
                            colspan="6"
                            class="tdCenter"
                            style="padding: 30px 0"
                          >
                            <b>No Records Found</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="pagination" v-if="last_page > 1">
                    <a
                      v-if="prev_page_url"
                      href="#"
                      @click="dopagination(prevPage)"
                      class="next"
                    >
                      « Previous
                    </a>
                    <span v-else class="disabled">« Previous</span>
                    <span v-if="last_page > max_display_pagination">
                      <span v-for="index in last_page">
                        <span v-if="index == 1 || index == 2">
                          <span class="current" v-if="index == current_page">
                            {{ index }}
                          </span>
                          <span v-else>
                            <a href="#" @click="dopagination(index)">
                              {{ index }}
                            </a>
                          </span>
                          <span v-if="index == 2 && firstDotted">..</span>
                        </span>
                        <span v-if="randomPageNumber.includes(index)">
                          <span class="current" v-if="index == current_page">
                            {{ index }}
                          </span>
                          <span v-else>
                            <a href="#" @click="dopagination(index)">
                              {{ index }}
                            </a>
                          </span>
                        </span>
                        <span
                          v-if="index == last_page || index == last_page - 1"
                        >
                          <span v-if="index == last_page - 1 && lastDotted">
                            ..
                          </span>
                          <span class="current" v-if="index == current_page">
                            {{ index }}
                          </span>
                          <span v-else>
                            <a href="#" @click="dopagination(index)">
                              {{ index }}
                            </a>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span v-else>
                      <span v-for="index in last_page">
                        <span class="current" v-if="index == current_page">
                          {{ index }}
                        </span>
                        <span v-else>
                          <a href="#" @click="dopagination(index)">{{
                            index
                          }}</a>
                        </span>
                      </span>
                    </span>
                    <a
                      v-if="next_page_url"
                      href="#"
                      @click="dopagination(nextPage)"
                      class="next"
                    >
                      Next »
                    </a>
                    <span v-else class="disabled">Next »</span>
                  </div>

                  <br />
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="box">
                        <div class="box-header">
                          <h3 class="box-title">Add Server To Bundle Form</h3>
                        </div>
                        <div class="box-body form-box-body form-horizontal">
                          <div class="form-group">
                            <label class="col-md-1 control-label"
                              >Name
                              <span class="mandatory_horizontal">*</span></label
                            >
                            <div class="col-md-3">
                              <input
                                name="show_name"
                                id="show_name"
                                class="form-control input-sm"
                                value=""
                                v-model="show_name"
                                placeholder="Name"
                                type="text"
                                readonly=""
                              />
                              <label
                                v-if="error_show_name"
                                class="error"
                                for="show_name"
                                >{{ error_show_name }}</label
                              >
                            </div>
                            <label class="col-md-1 control-label"
                              >IP
                              <span class="mandatory_horizontal">*</span></label
                            >
                            <div class="col-md-3">
                              <input
                                name="show_ip"
                                id="show_ip"
                                class="form-control input-sm"
                                value=""
                                v-model="show_ip"
                                placeholder="IP Address"
                                type="text"
                                readonly=""
                              />
                              <label
                                v-if="error_show_ip"
                                class="error"
                                for="show_name"
                                >{{ error_show_ip }}</label
                              >
                            </div>
                            <label class="col-md-1 control-label"
                              >Priority
                              <span class="mandatory_horizontal">*</span></label
                            >
                            <div class="col-md-3">
                              <input
                                required=""
                                name="priority"
                                id="priority"
                                class="form-control input-sm"
                                value=""
                                v-model="priority"
                                placeholder="Priority"
                                type="number"
                                min="0"
                                onfocus="this.removeAttribute('readonly');"
                                readonly=""
                                aria-required="true"
                              />
                            </div>
                          </div>

                          <div class="box-footer btnCenter">
                            <input
                              type="submit"
                              @click="SaveIpToBundle()"
                              name="submit"
                              value="Submit"
                              class="btn btn-sm btn-submit"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "IpBundleDtlsList",
  data() {
    return {
      dataCollections: [],
      ipList: [],
      user_data: {},
      name: "",
      ip: "",
      type: "",
      note: "",
      alert_message: "",
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      status: "",
      bundle_id: "",
      ip_bundle_name: "",
      sl: 1,

      show_name: "",
      show_ip: "",
      ips_id: "",
      priority: "",
      error_show_name: false,
      error_show_ip: false,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    this.loadIpList();
  },
  methods: {
    loadIpList() {
      loader.show();
      var rootObject = this;
      this.bundle_id = this.$route.params.id;
      axios
        .get("ip-bundle-details/" + this.bundle_id)
        .then(function (response) {
          //console.log(response);
          rootObject.ip_bundle_name = response.data.bundle_name;
          rootObject.dataCollections = response.data.ipBundleDetails;
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    removeData(data) {
      var rootObject = this;
      let msg = "Do you really want to Delete?";

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            axios
              .delete(`ip-bundle-details/${data.id}`)
              .then((response) => {
                rootObject.alert_message = "Deleted Successfully !";
                rootObject.loadIpList();
                setTimeout(function () {
                  rootObject.alert_message = "";
                }, 3000);
              })
              .catch((error) => console.error(error));
          },
          no: function () {
            //return;
          },
        },
      });
    },

    contentEdit(id) {
      var valId = "#priority-" + id;
      $(valId).prop("contenteditable", true);
      $(valId).focus();
    },

    updatePriority(id) {
      var rootObject = this;
      var valId = "#priority-" + id;
      var originalVal = $(valId).attr("original-value");
      $(valId).css("cursor", "default");
      $(valId).prop("contenteditable", false);

      var id = id;
      var newPriority = $(valId).html();
      var newPriority = newPriority.replace("<br>", "");
      var newPriority = newPriority.replace("&nbsp;", "");
      var newPriority = newPriority.replace(/ /g, "");

      if (newPriority == originalVal) return;
      if (newPriority == "") {
        $.alert("priority can not be empty");
        $(valId).text(originalVal);
        return;
      }

      if (isNaN(newPriority)) {
        $.alert("Please, input only number value");
        $(valId).text(originalVal);
        return;
      }

      $(valId).text(newPriority);

      loader.show();
      const form = new FormData();
      form.append("id", id);
      form.append("priority", newPriority);
      form.append("_method", "put");
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("ip-bundle-details/" + id, form, config)
        .then(function (response) {
          //console.log(response);
          if (response.status == 200) {
            rootObject.alert_message = "Priority updated successfully !";
            loader.hide();
            setTimeout(function () {
              rootObject.alert_message = "";
            }, 3000);
          }
        })
        .catch(function (error) {
          loader.hide();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          $.alert(alertmessage);
        });
    },

    addNewIpToBundle() {
      this.name = "";
      this.ip = "";
      this.type = "";
      this.note = "";
      this.display_limit = 50;
      this.status = "";
      this.show_name = "";
      this.show_ip = "";
      this.ips_id = "";
      this.priority = parseInt(this.dataCollections.length) + 1;
      this.dopagination(0, true);
    },

    dopagination(page_no = "", loadpopup = false) {
      loader.show();
      var rootObject = this;
      let querystr =
        "name=" +
        this.name +
        "&ip=" +
        this.ip +
        "&type=" +
        this.type +
        "&status=" +
        this.status +
        "&note=" +
        this.note +
        "&paginate=" +
        this.display_limit;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);
      axios
        .get("ips?" + querystr)
        .then(function (response) {
          rootObject.populateData(response);
          if (loadpopup) {
            $(".colorbox").colorbox({
              opacity: 0.8,
              height: "550px",
              width: "80%",
              inline: true,
              open: true,
              href: function () {
                return "#IPListBox";
              },
            });
          }
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateData(response) {
      var responseData = response.data;
      //console.log(responseData.data);
      this.sl = parseInt(responseData.from);
      this.ipList = responseData.data;
      this.totalData = responseData.total;
      this.dispplayFrom = responseData.from;
      this.dispplayTo = responseData.to;
      this.display_limit = responseData.per_page;
      this.paginationGenerate(responseData);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.prev_page_url;
      this.next_page_url = response.next_page_url;
      this.last_page = response.last_page;
      this.current_page = response.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },

    loadIPInformation(ipData) {
      this.show_name = ipData.name;
      this.show_ip = ipData.ip;
      this.ips_id = ipData.id;
      //this.priority = '';
    },

    SaveIpToBundle() {
      loader.show();
      var rootObject = this;
      var result = this.fromValidation();
      if (result) {
        return 0;
      }
      const form = new FormData();
      form.append("ip_bundle_id", this.bundle_id);
      form.append("ips_id", this.ips_id);
      form.append("priority", this.priority);
      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      axios
        .post("ip-bundle-details", form, config)
        .then(function (response) {
          //console.log(response);
          if (response.status == 200) {
            $.fn.colorbox.close();
            rootObject.alert_message = "IP Added successfully !";
            rootObject.loadIpList();
            loader.hide();
            setTimeout(function () {
              rootObject.alert_message = "";
            }, 3000);
          }
        })
        .catch(function (error) {
          loader.hide();
          $.fn.colorbox.close();
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          //console.log(alertmessage);
          $.alert(alertmessage);
        });
    },

    fromValidation(reset = false) {
      var rootObject = this;
      var has_error = false;
      if (!this.show_name) {
        this.error_show_name = "This field is required";
        has_error = true;
      }
      if (!this.show_ip) {
        this.error_show_ip = "This field is required";
        has_error = true;
      }

      if (has_error) {
        setTimeout(function () {
          rootObject.error_show_name = false;
          rootObject.error_show_ip = false;
        }, 5000);
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: function () {
    $(".select2").select2();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
