<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="row">
        <div class="col-xs-8" align="left">
          <h1>Search Customer Feedback</h1>
        </div>
        <div class="col-xs-4">
          <a class="btn btn-sm btn-add" @click="RefreshNow">
            <i class="fa fa-refresh"></i>Refresh
          </a>
        </div>
      </div>
    </section>
    <section class="content">
      <form v-on:submit.prevent="dopagination(0)">
        <div class="row">
          <div class="col-xs-6 col-sm-3 form-group">
            <input
              placeholder="Name"
              type="text"
              name="full_name"
              v-model="full_name"
              id="full_name"
              class="form-control input-sm"
              value
            />
          </div>
          <div class="col-xs-3 col-sm-1 form-group">
            <select
              title
              name="limit"
              id="limit"
              v-model="display_limit"
              v-select="display_limit"
              class="form-control input-sm select2"
              @change="dopagination(0)"
            >
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
            </select>
          </div>
          <div class="col-xs-2 form-group">
            <button type="submit" class="btn btn-sm btn-submit">
              <i class="fa fa-search"></i> Search
            </button>
          </div>
        </div>
      </form>
      <div class="row">
        <div class="col-sm-12">
          <div class="alert alert-info" v-if="alert_message">{{ alert_message }}</div>
        </div>
        <div class="col-md-12">
          <div class="box">
            <div class="box-header">
              <h3 class="box-title">
                Total : {{ totalData }}
                <span
                  v-if="dispplayFrom"
                >Showing {{ dispplayFrom }} to {{ dispplayTo }}</span>
                <span class="pull-right noOfData">{{ totalData }}</span>
              </h3>
            </div>
            <div class="box-body">
              <div class="table-responsive">
                <table class="table table-0024 table-bordered table-striped">
                  <thead>
                    <tr>
                      <th class="tdCenter tdSN">SN</th>
                      <th>Username</th>
                      <th>Keywords</th>
                      <th>Feedback</th>
                      <th>Emoji</th>
                    </tr>
                  </thead>
                  <tbody v-if="resellerArr.length > 0">
                    <tr v-for="(data, index) in resellerArr" :key="data.id">
                      <td class="tdCenter tdSN">{{ parseInt(index) + 1 }}</td>
                      <td>{{ data.username }}</td>
                      <td>{{ data.keywords }}</td>
                      <td>{{ data.feedback_text }}</td>
                      <td>{{ data.selected_emoji }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4" class="tdCenter" style="padding: 30px 0">
                        <b>No Records Found</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="pagination" v-if="last_page > 1">
                <a
                  v-if="prev_page_url"
                  href="#"
                  @click="dopagination(prevPage)"
                  class="next"
                >« Previous</a>
                <span v-else class="disabled">« Previous</span>
                <span v-if="last_page > max_display_pagination">
                  <span v-for="index in last_page">
                    <span v-if="index == 1 || index == 2">
                      <span class="current" v-if="index == current_page">{{ index }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                      <span v-if="index == 2 && firstDotted">..</span>
                    </span>
                    <span v-if="randomPageNumber.includes(index)">
                      <span class="current" v-if="index == current_page">{{ index }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                    <span v-if="index == last_page || index == last_page - 1">
                      <span v-if="index == last_page - 1 && lastDotted">..</span>
                      <span class="current" v-if="index == current_page">{{ index }}</span>
                      <span v-else>
                        <a href="#" @click="dopagination(index)">{{ index }}</a>
                      </span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  <span v-for="index in last_page">
                    <span class="current" v-if="index == current_page">{{ index }}</span>
                    <span v-else>
                      <a href="#" @click="dopagination(index)">{{ index }}</a>
                    </span>
                  </span>
                </span>
                <a v-if="next_page_url" href="#" @click="dopagination(nextPage)" class="next">Next »</a>
                <span v-else class="disabled">Next »</span>
              </div>
            </div>
            <div class="box-footer"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "ResellerList",
  data() {
    return {
      totalResellerCreated: 0,
      limit_user: 0,
      resellerArr: {},
      login: "",
      full_name: "",
      status: 1,
      dispplayFrom: 0,
      dispplayTo: 0,
      totalData: 0,
      display_limit: 50,
      payment_history: {},
      payment_reseller: {},
      payment_rate_plan_details: {},
      no_of_pins: "",
      amount: "",
      pay_amount: "",
      type: 1,
      creationdate: "",
      description: "",
      alert_message: "",
      balance_alert_limit: 10,
      userData: "",

      error_no_of_pins: false,
      error_amount: false,
      error_description: false,

      // Pagination Variables
      prev_page_url: false,
      next_page_url: false,
      last_page: 1,
      current_page: null,
      max_display_pagination: 9,
      randomPageNumber: {},
      firstDotted: false,
      lastDotted: false,
      prevPage: "",
      nextPage: "",
    };
  },
  created() {
    var encryptedData = this.$store.getters.getUser;
    var decodedString = this.com_DecryptString(encryptedData);
    var userData = JSON.parse(decodedString);
    this.userData = userData;
    if (userData.user_level == 1) {
      this.$router.push("dashboard");
    }

    //this.loadAllResellers();
    this.dopagination(0);
  },
  methods: {
    dopagination(page_no = "") {
      loader.show();
      var rootObject = this;
      rootObject.creationdate = this.com_currentDateTime();
      let querystr =
        "full_name=" + this.full_name + "&paginate=" + this.display_limit;
      if (page_no > 0) {
        querystr += "&page=" + page_no;
      }
      //console.log(querystr);

      axios
        .get("customer-feedback?" + querystr)
        .then(function (response) {
          rootObject.populateResellerData(response);
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    populateResellerData(response) {
      this.resellerArr = response.data.resellerData.data;
      this.totalData = response.data.resellerData.total;
      this.dispplayFrom = response.data.resellerData.from;
      this.dispplayTo = response.data.resellerData.to;
      this.display_limit = response.data.resellerData.per_page;

      this.totalResellerCreated = response.data.totalResellerCreated;
      this.limit_user = response.data.limit_user;

      this.paginationGenerate(response);
      loader.hide();
    },

    paginationGenerate(response) {
      this.prev_page_url = response.data.resellerData.prev_page_url;
      this.next_page_url = response.data.resellerData.next_page_url;
      this.last_page = response.data.resellerData.last_page;
      this.current_page = response.data.resellerData.current_page;
      this.randomPageNumber = [];
      this.firstDotted = false;
      this.lastDotted = false;
      this.prevPage = this.current_page - 1;
      this.nextPage = this.current_page + 1;
      let countableOtherPages = this.max_display_pagination - 4;
      if (this.current_page == 1 || this.current_page == 2) {
        for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
          this.randomPageNumber.push(i);
        }
        this.lastDotted = true;
      } else if (
        this.current_page == this.last_page ||
        this.current_page == this.last_page - 1
      ) {
        let endPage = this.last_page - 2;
        for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
          this.randomPageNumber.push(i);
        }
        this.firstDotted = true;
      } else {
        let interval = parseInt(countableOtherPages / 2);
        if (this.current_page <= interval + 3) {
          for (let i = 3; i <= 3 + (countableOtherPages - 1); i++) {
            this.randomPageNumber.push(i);
          }
          this.lastDotted = true;
        } else if (this.current_page >= this.last_page - (interval + 2)) {
          let endPage = this.last_page - 2;
          for (let i = endPage; i >= endPage - (countableOtherPages - 1); i--) {
            this.randomPageNumber.push(i);
          }
          this.firstDotted = true;
        } else {
          for (
            let i = this.current_page;
            i <= this.current_page + interval;
            i++
          ) {
            this.randomPageNumber.push(i);
          }

          for (
            let i = this.current_page - 1;
            i >= this.current_page - interval;
            i--
          ) {
            this.randomPageNumber.push(i);
          }
        }
      }
      //console.log(this.randomPageNumber);
    },

    changeStatus(id, old_status) {
      var rootObject = this;
      let status = old_status == 0 ? 1 : 0;
      //console.log(id+ " " +status);
      let msg = "";
      msg =
        status == 1
          ? "Do you really want to Active this Reseller"
          : "Do you really want to Inactive this Reseller";

      $.confirm({
        title: "Confirm!",
        content: msg,
        buttons: {
          yes: function () {
            const form = new FormData();
            form.append("status", status);
            form.append("only_status", true);
            form.append("_method", "put");
            var config = {
              headers: { "Content-Type": "multipart/form-data" },
            };
            axios
              .post("resellers/" + id, form, config)
              .then(function (response) {
                //console.log(response);
                if (response.status == 200) {
                  rootObject.dopagination(0);
                  rootObject.alert_message = "Status successfully changed";
                  setTimeout(function () {
                    rootObject.alert_message = "";
                  }, 3000);
                }
              })
              .catch(function (error) {
                rootObject.com_errorHandel(error);
                let alertmessage = "";
                for (var prop in error.response.data) {
                  alertmessage += " " + error.response.data[prop];
                }
                //console.log(alertmessage);
                $.alert(alertmessage);
              });
          },
          no: function () {
            //return;
          },
        },
      });
    },

    loadRecharge(reseller_id, rate_plan_id) {
      loader.show();
      this.error_no_of_pins = false;
      this.error_amount = false;
      this.error_description = false;
      var rootObject = this;
      rootObject.no_of_pins = "";
      rootObject.amount = "";
      rootObject.pay_amount = "";
      rootObject.description = "";
      axios
        .get("recharge/" + reseller_id + "?rate_plan_id=" + rate_plan_id)
        .then(function (response) {
          //console.log(response.data);
          rootObject.payment_reseller = response.data.reseller;
          rootObject.payment_history = response.data.paymentHistory;
          rootObject.payment_rate_plan_details =
            response.data.rate_plan_details;
          $(".colorbox").colorbox({
            opacity: 0.8,
            height: "550px",
            width: "80%",
            inline: true,
            open: true,
            href: function () {
              return "#RechargeBox";
            },
          });
          loader.hide();
        })
        .catch(function (error) {
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.com_errorHandel(error);
        });
    },

    addBalance(no_of_pins, recharged_balance, amount) {
      this.amount = recharged_balance;
      this.no_of_pins = no_of_pins;
      this.pay_amount = amount;
    },

    setPinAndAmount(type) {
      let price = this.payment_rate_plan_details[0].price;
      if (type == "price") {
        this.no_of_pins = parseInt(this.amount / price);
      }

      if (type == "pin") {
        this.amount = this.no_of_pins * price;
      }
    },

    saveRecharge(e) {
      e.preventDefault();
      let rootObject = this;

      var result = this.fromValidation();
      if (result) {
        return 0;
      }
      loader.show();
      const form = new FormData();
      form.append("amount", this.amount);
      form.append("no_of_pins", this.no_of_pins);
      form.append("priceofpin", this.payment_rate_plan_details[0].price);
      form.append("id_reseller", this.payment_reseller.id);
      form.append("lastBalance", this.payment_reseller.balance);
      form.append("type", this.type);
      form.append("description", this.description);
      form.append("pay_amount", this.pay_amount);

      var config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      axios
        .post("recharge", form, config)
        .then(function (response) {
          //console.log(response);
          rootObject.alert_message =
            "You have successfully add your reseller payment.";
          setTimeout(function () {
            rootObject.alert_message = "";
          }, 2000);
          rootObject.dopagination(0);
          loader.hide();
        })
        .catch(function (error) {
          rootObject.com_errorHandel(error);
          let alertmessage = "";
          for (var prop in error.response.data) {
            alertmessage += " " + error.response.data[prop];
          }
          console.log(alertmessage);
          loader.hide();
          rootObject.alert_message = alertmessage;
          setTimeout(function () {
            rootObject.alert_message = "";
          }, 5000);
        });

      $.colorbox.close();
    },

    fromValidation(reset = false) {
      var has_error = false;
      this.error_no_of_pins = false;
      this.error_amount = false;
      this.error_description = false;

      if (!this.no_of_pins) {
        this.error_no_of_pins = "This field is required";
        has_error = true;
      }

      if (!this.amount) {
        this.error_amount = "This field is required";
        has_error = true;
      }

      if (!this.description) {
        this.error_description = "This field is required";
        has_error = true;
      }

      if (has_error) {
        return true;
      } else {
        return false;
      }
    },

    RefreshNow() {
      this.login = "";
      this.full_name = "";
      this.status = 1;
      this.display_limit = 50;
      this.dopagination(0);
    },
  },

  mounted: function () {
    $(".select2").select2();
    $(".stop-negative-value").keydown(function (e) {
      if (
        !(
          (e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode == 8
        )
      ) {
        return false;
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
